/* ==========================================================================
   Playbutton component
   - All playbuttons have same behaviour, that is why it is a component.
   - Found in player component, welcomeplayer component, audio cards
   ========================================================================== */

.c-playbutton {
    display: flex;
    align-items: center;

    & svg[data-icon-pause] {
        display: none;
    }

    &.is-loading {
        & svg[data-icon-play] {
            display: none;
        }

        & svg[data-icon-pause] {
            display: none;
        }

        &:after {
            content: "";
            display: block;
            width: rem(22);
            height: rem(22);
            background: currentColor;
            border-radius: 50%;
            transform: scale(1);
            animation: heartbeat 2s infinite;
        }
    }

    &.is-playing {
        & svg[data-icon-play] {
            display: none;
        }

        & svg[data-icon-pause] {
            display: block;
        }
    }

    &--player {
        svg {
            height: rem(22);
            width: rem(22);

            @include breakpoint(medium) {
                height: rem(40);
                width: rem(40);
            }
        }
    }

    &--contentplaybar {
        justify-content: center;
        color: cssvar(color-primary);
        background-color: cssvar(color-white);
        border-radius: 50% !important;
        box-shadow: $box-shadow;
        clip-path: none !important;
        z-index: 2;
        width: rem(67);
        height: rem(67);

        @include breakpoint(medium) {
            width: rem(78);
            height: rem(78);
        }

        @include tpl(rockantenne) {
            color: cssvar(color-black);

            svg {
                fill: cssvar(color-black);
            }
        }

        @include tpl(oldieantenne) {
            color: cssvar(color-black);

            svg {
                fill: cssvar(color-black);
            }
        }

        svg {
            width: rem(31);
            height: rem(31);
            fill: cssvar(color-primary);

            @include breakpoint(medium) {
                height: rem(36);
                height: rem(36);
            }
        }

        .c-playbutton__icon--play {
            transform: translateX(rem(4));
        }

        &:hover {
            animation: wobbleScale 1s ease;
        }

        // Styling used for component within main content area
        &--main {

            @include tpl(antenne) {
                background-color: cssvar(color-primary);

                svg {
                    fill: cssvar(color-white);
                }
            }
        }
    }

    &--in-toolbar {
        padding-right: rem(2) !important;
    }
}
