// Attention: When changing this, adjust also the duration in the Offcanvas.js!
$offcanvas-animation-duration: 0.4s;

.c-offcanvas {
    z-index: 100;
    display: none;
    justify-content: flex-end;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(100%);
    color: cssvar(color-white);
    background: rgba(0, 0, 0, 0.0);
    transition: transform linear 0s $offcanvas-animation-duration, background-color ease-in-out $offcanvas-animation-duration 0.1s, backdrop-filter ease-in-out $offcanvas-animation-duration;
    overflow: hidden;

    @include tpl(oldieantenne) {
        --overlay: #{cssvar(color-white)};
        --background: #{cssvar(color-background)};
        --alpha: 88%;
    }

    &__body {
        position: relative;
        left: 0;
        transform: translateX(rem(320));
        // lets keep the width at 320px even for mobile for better UX (screendesign says 248 for mobile, wich is very small)
        width: rem(320);
        max-width: 100%;
        transition: transform ease-in-out 0.4s;
        overflow-x: hidden;
        overflow-y: auto;
        // TODO: we need color-variables in HSL to allow transparency!
        background: rgb(0 71 151 / 70%);
        background: color-mix(in srgb, var(--background, cssvar(color-primary)) var(--alpha, 70%), transparent);
    }


    &:target,
    &.is-active {
        display: flex;
    }

    &:target,
    &.is-animating {
        transform: translateX(0);
        background: rgba(0, 0, 0, 0.3);
        background: color-mix(in srgb, var(--overlay, cssvar(color-black)) 30%, transparent);
        transition: transform linear 0s 0s, background-color ease-in-out $offcanvas-animation-duration 0.1s, backdrop-filter ease-in-out $offcanvas-animation-duration;
        backdrop-filter: blur(2px);

        .c-offcanvas__body {
            transform: translateX(0);
        }
    }

    &__header {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        @include breakpoint(medium) {
            justify-content: flex-start;
            margin-top: rem(16);
        }
    }

    &__account {
        border-radius: 20%/50%;
        color: black;
        mix-blend-mode: screen;
        background: cssvar(color-white);
        padding: rem(4 7);
        transition: background-color ease-in-out .2s;
        font-family: cssvar(header-font-family);
        letter-spacing: cssvar(header-font-spacing);
        font-size: rem(14);
        line-height: rem(14);
        font-weight: bold;
        text-decoration: none;

        &:hover {
            background: cssvar(color-secondary);
            color: black;
        }

        @include breakpoint(medium) {
            display: none;
        }

        @include tpl(rockantenne) {
            text-transform: uppercase;
        }
    }

    &__close,
    &__close:visited,
    &__close:active {
        display: inline-block;
        margin: rem(10 17);
        padding: rem(8);
        color: cssvar(color-white);
        line-height: 1;

        @include breakpoint(medium) {
            margin-left: rem(14);
        }
    }

    &__closeicon {
        width: rem(16);
        height: rem(16);
    }

    &__list,
    &__sublist {
        list-style: none;
        display: block;
        margin: 0;
        padding: 0;
    }

    &__list {

        @include tpl(antenne) {
            border-bottom: 2px solid cssvar(color-white);
        }
    }

    &__checkbox {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        border-width: 0;
    }

    &__link,
    &__sublink {
        display: block;
        text-decoration: none;
        cursor: pointer;
        outline: none;
    }

    &__link {
        position: relative;
        padding: rem(16 20);
        font-family: cssvar(header-font-family);
        letter-spacing: cssvar(header-font-spacing);
        font-weight: $global-weight-bold;
        transition: color ease-in-out 0.2s, background-color ease-in-out 0.2s;

        // CS 2022-03-23:
        // Attention: "fixing" font smoothing seems to be a bad practice,
        // but was done here (for now) anyway to reflect better the screendesigns
        // read https://usabilitypost.com/2012/11/05/stop-fixing-font-smoothing/
        // and consider not doing this?
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

       &[data-secondarybackground] {
            background: cssvar(color-secondary);

            @include tpl(antenne) {
                color: cssvar(color-primary);
            }

            &:hover {
                background: cssvar(color-white);
                color: cssvar(color-primary);

                @include tpl(rockantenne) {
                    color: cssvar(color-darkblue);
                }

                @include tpl(oldieantenne) {
                    color: cssvar(color-background);
                }
            }
        }

        @include tpl(rockantenne) {
            text-transform: uppercase;
            padding: rem(16 20 13);
        }

        &:hover {
            color: cssvar(color-secondary);
        }
    }

    &__listitem--with-sublist {
        .c-offcanvas__link {

            &::before,
            &::after {
                content: "";
                position: absolute;
                right: rem(16);
                top: 58%;
                display: block;
                height: rem(2.5);
                width: rem(12);
                border-radius: rem(2.5);
                background: currentColor;
            }

            &::before {
                transform: rotate(46deg) translateX(rem(-5));
            }

            &::after {
                transform: rotate(134deg) translateX(rem(-5));
            }
        }
    }

    &__sublist {
        max-height: 0rem;
        transition: max-height ease-in-out 0.3s, margin-top ease-in-out 0.3s, padding-top ease-in-out 0.3s;
        background: cssvar(color-white);
        overflow: hidden;

        @include tpl(rockantenne) {
            background: cssvar(color-black);
        }

        &[aria-hidden="true"] {
            display: none;
        }
    }

    .c-offcanvas.u-supports-js &__checkbox.is-active,
    .c-offcanvas:not(.u-supports-js) &__checkbox:checked {
        ~.c-offcanvas__sublist {
            // --item-count is dynamically set inline; added 1x50px manually for margin/padding
            max-height: calc(rem(51) + rem(50) * var(--item-count, 20));
            padding-top: rem(51);
            margin-top: rem(-51);
        }

        ~.c-offcanvas__link {
            color: cssvar(color-primary);

            @include tpl(rockantenne) {
                color: cssvar(color-white);
            }

            @include tpl(oldieantenne) {
                color: cssvar(color-black);
            }
        }

        &:focus-visible {
            ~.c-offcanvas__link {
                color: cssvar(color-secondary);
            }
        }

        ~.c-offcanvas__link::after {
            transform: rotate(226deg) translate(rem(1), rem(5));
        }

        ~.c-offcanvas__link::before {
            transform: rotate(314deg) translate(0px, rem(-6));
        }
    }

    &__checkbox:focus-visible {
        ~.c-offcanvas__link {
            color: cssvar(color-secondary);
        }
    }

    &__sublistitem {
        &:last-child {
            margin-bottom: rem(8);
        }
    }

    &__sublink {
        padding: rem(8 20);

        @include tpl(antenne) {
            color: cssvar(color-primary);
        }

        @include tpl(rockantenne) {
            color: cssvar(color-white);
        }

        @include tpl(oldieantenne) {
            color: cssvar(color-black);
        }

        &:hover,
        &:focus:not(:focus-visible) {
            @include tpl(antenne) {
                color: cssvar(color-tertiary);
            }

            @include tpl(rockantenne, oldieantenne) {
                color: cssvar(color-secondary);
            }
        }
    }

    &__footer {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    // Search form
    &__search {
        display: flex;
        align-items: flex-start;
        padding: rem(16 12 13 15);
        input[type="search"] {
            display: block;
            appearance: none;
            background: transparent;
            color: cssvar(color-foreground);
            box-shadow: none;
            border: none;
            font-size: rem(16);
            font-family: inherit;

            &::placeholder {
                color: cssvar(color-foreground);
                font-size: inherit;
                font-family: inherit;
            }

            &:focus {
                outline: none;
            }

            &::-webkit-search-decoration,
            &::-webkit-search-cancel-button,
            &::-webkit-search-results-button,
            &::-webkit-search-results-decoration {
                display: none;
            }
        }
        button {
            display: block;
            padding: 0; // needed to override normal button padding for iOS.
            margin: 0 rem(5);
        }
    }
}
