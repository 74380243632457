.c-link {
    z-index: 1;

    a,
    button {
        display: flex;
        align-self: end;
        text-decoration: none;
        color: currentColor;
    }

    &:hover {

        a,
        span {
            transition: color 0.3s ease-in-out;
            color: cssvar(color-secondary);
        }

        .c-link__icon {
            fill: cssvar(color-secondary);
        }
    }

    &__icon {
        width: var(--icon-width, rem(20));
        height: var(--icon-height, rem(20));
        fill: currentColor;
    }

    span {
        margin-left: rem(10);
    }
}

//variations
//footer variation
.c-link--footer {
    margin-bottom: rem(20);

    @include breakpoint(medium) {
        margin-bottom: rem(40);
    }

    a {
        align-items: flex-end;
        color: cssvar(color-white);
    }
}

// icon on right side
.c-link--right {
    a {
        align-items: baseline;
    }

    span {
        margin: rem(0 10 0 0);
    }
}

//offcanvas variation
.c-link--offcanvas {

    a {
        font-weight: bold;
        align-items: center;
        color: cssvar(color-white);
    }

    &:first-child {
        padding: rem(16 20);
    }

    &:not(:first-child) {
        margin-bottom: rem(24);
    }

    font-family: cssvar(header-font-family);
    letter-spacing: cssvar(header-font-spacing);

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @include tpl(rockantenne) {
        text-transform: uppercase;
    }
}
