.c-newsticker {
    background: cssvar(color-secondary);
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    height: $newstickerHeight;
    z-index: 99;
    display: flex;
    align-items: center;
    box-shadow: $box-shadow;

    &__text {
        display: inline-block;
        width: auto;
        margin: 0;
        padding: 0 0 rem(2);
        line-height: 1;
        white-space: nowrap;
        // duration is set via JS
        animation: marquee linear infinite var(--animationduration, 0);
        color: cssvar(color-primary);

        @include tpl(rockantenne) {
            color: cssvar(color-white);
        }

        // Targets devices where input mechanism includes an accurate pointing device (aka a mouse, aka not mobile)
        @media (pointer:fine) {
            &:hover {
                animation-play-state: paused;
            }
        }
    }

    &,
    a,
    a:hover,
    a:focus {
        color: currentColor;
        text-decoration: none;
    }
}

html.template--nativeapp .c-newsticker {
    display: none;
}

html.has-newsticker .l-page-header {
    transition: margin 0.3s ease-in-out;
    margin-top: $newstickerHeight;
}

html.has-newsticker #nprogress .bar {
    top: $newstickerHeight + rem(1);
}
