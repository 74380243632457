@import 'fonts';

$sizesIncrement: 1.10;
$fontSize0: 16;
$fontSize1: $fontSize0 * $sizesIncrement;
$fontSize2: $fontSize1 * $sizesIncrement;
$fontSize3: $fontSize2 * $sizesIncrement;
$fontSize4: $fontSize3 * $sizesIncrement;
$fontSize5: $fontSize4 * $sizesIncrement;
$fontSize6: 32;
$fontSize7: 48;

@include setrootcssvars((
    lineHeightFixedAmount: 0.25rem,
    lineHeightRelativeAmount: 1.15em,
    sizesIncrement: $sizesIncrement,

    fontSize-7: round($fontSize7),
    fontSize-6: round($fontSize6),
    fontSize-5: round($fontSize5),
    fontSize-4: round($fontSize4),
    fontSize-3: round($fontSize3),
    fontSize-2: round($fontSize2),
    fontSize-1: round($fontSize1),
    fontSize-0: round($fontSize0),

    // trick from https://twitter.com/hihayk/status/1280979972258172928
    // nice tool for this at https://hihayk.github.io/doppler/
    globalLineHeight: calc(cssvar(lineHeightFixedAmount) + cssvar(lineHeightRelativeAmount)),
));

@include tpl(oldieantenne) {
    @include setcssvars((
        fontSize-1: 17,
        fontSize-2: 17,
    ));
}

* {
    line-height: cssvar(globalLineHeight);
}

html {
    font-size: 100%;
}

body {
    font-size: 1rem;
    font-family: cssvar(body-font-family);
    accent-color: cssvar(color-foreground);
    color: cssvar(color-foreground);
}

h1,
h2,
h3,
h4,
h5,
h6,
.o-headline--size1,
.o-headline--size2,
.o-headline--size3,
.o-headline--size4,
.o-headline--size5,
.o-headline--size6,
.o-headline--extra-large {
    margin: 1.25em 0 $global-margin;
    color: var(--heading-color, currentColor);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: cssvar(header-font-family);
    letter-spacing: cssvar(header-font-spacing);
    font-weight: $global-weight-bold;

    &[data-notation="highlight"] {
        @include tpl(rockantenne) {
            color: cssvar(color-darkblue);
        }
    }
}



// Fluid type settings
// https: //dev.to/ixkaito/fluid-typography-using-css-custom-properties-css-variables-3lcn
/**
* Available vars:
* @var --viewport-from: <number> - Number in pixels without the unit. Required if `--font-size` is not exist.
* @var --viewport-to: <number> - Number in pixels without the unit. Required if `--font-size` is not exist.
* @var --font-size-from: <number> - Number in pixels without the unit. Required if `--font-size` and `--min-font-size` is not exist.
* @var --font-size-to: <number> - Number in pixels without the unit. Required if `--font-size` and `--max-font-size` is not exist.
* @var --max-font-size: <number> - Number in pixels without the unit. Optional.
* @var --min-font-size: <number> - Number in pixels without the unit. Optional.
* @var --viewport-unit-converter: 1vw | 1vh | 1vmin | 1vmax - Optional. Default: 1vw.
* @var --font-size: <length> | <percentage> | <absolute-size> | <relative-size> | Global values - Optional.
*/
h1,
h2,
h3,
h4,
h5,
h6,
.o-headline--size1,
.o-headline--size2,
.o-headline--size3,
.o-headline--size4,
.o-headline--size5,
.o-headline--size6,
.o-headline--extra-large {
    --viewport-unit-converter: 1vw;
    --fz-from: var(--font-size-from, var(--min-font-size));
    --fz-to: var(--font-size-to, var(--max-font-size));
    --fz-slope: (var(--fz-to) - var(--fz-from)) / (var(--viewport-to) - var(--viewport-from)) * 100;
    --fz-intercept: (var(--viewport-to) * var(--fz-from) - var(--viewport-from) * var(--fz-to)) / (var(--viewport-to) - var(--viewport-from));
    --font-size: calc(var(--fz-slope) * var(--viewport-unit-converter) + var(--fz-intercept) * 1px);

    --min-fz-px: calc(var(--min-font-size) * 1px);
    --max-fz-px: calc(var(--max-font-size) * 1px);
    --clamp: clamp(var(--min-fz-px), var(--font-size), var(--max-fz-px));
    --max: var(--has-max, var(--min));
    --min: var(--has-min, var(--font-size));
    --has-max: min(var(--max-fz-px), var(--font-size));
    --has-min: max(var(--min-fz-px), var(--font-size));
    --global-viewport-from: 375;
    --global-viewport-to: 1020;
    --viewport-from: var(--global-viewport-from);
    --viewport-to: var(--global-viewport-to);
    font-size: var(--clamp, var(--max));

    @include breakpoint(small, down) {
        text-wrap: balance;
    }
}

.o-headline--extra-large {
    --min-font-size: 48;
    --max-font-size: #{cssvar(fontSize-7)};
}

h1,
.o-headline--size1 {
    --min-font-size: 28;
    --max-font-size: #{cssvar(fontSize-6)};
}

h2,
.o-headline--size2 {
    --min-font-size: 24;
    --max-font-size: #{cssvar(fontSize-5)};
}

h3,
.o-headline--size3 {
    --min-font-size: 18;
    --max-font-size: #{cssvar(fontSize-4)};
}

h4,
.o-headline--size4 {
    --min-font-size: 18;
    --max-font-size: #{cssvar(fontSize-3)};
}

h5,
.o-headline--size5 {
    --min-font-size: 18;
    --max-font-size: #{cssvar(fontSize-2)};

    @include tpl(oldieantenne) {
        --min-font-size: 17;
    }
}

h6,
.o-headline--size6 {
    --min-font-size: 16;
    --max-font-size: #{cssvar(fontSize-1)};
}

.o-headline--size7 {
    font-size: rem(16);
    line-height: 1.2;
}

p {
    margin: 0 0 $global-margin;
}


 // Focus and hover effects only for devices with fine pointers. This excludes touch devices.
 // Touch devices keep focus style after they have been used and do not reset back to original state.
a:not(.c-button),
a:not(.c-button):focus:not(:focus-visible),
button:not(.c-button),
button:focus:not(.c-button):not(:focus-visible) {
    transition: color 0.3s ease-in-out;
    color: cssvar(color-link);
    @media (pointer: fine) {
        &:hover,
        &:focus-visible {
            color: cssvar(color-link-hover);
        }
    }
}



// show href value when a tag has no text value (https://twitter.com/AllThingsSmitty/status/946381631836901377)
a[href^="http"]:empty::before {
    content: attr(href);
}

// SVGs in current font color
svg {
    fill: currentColor;
    transition: fill .3s ease-in-out;
}

address {
    font-style: normal;
}
