/* asap-regular - latin */
@font-face {
    font-family: 'Asap';
    font-style: normal;
    font-weight: 400;
    font-display: fallback;
    src: local('Asap Regular'),
        url('asap-v21-latin-regular.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('asap-v21-latin-regular.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* asap-700 - latin */
@font-face {
    font-family: 'Asap';
    font-style: normal;
    font-weight: 700;
    font-display: fallback;
    src: local('Asap Bold'),
        url('asap-v21-latin-700.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('asap-v21-latin-700.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* asap-italic - latin */
@font-face {
    font-family: 'Asap';
    font-style: italic;
    font-weight: 400;
    font-display: fallback;
    src: local('Asap Italic'),
        url('asap-v21-latin-italic.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('asap-v21-latin-italic.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* asap-700italic - latin */
@font-face {
    font-family: 'Asap';
    font-style: italic;
    font-weight: 700;
    font-display: fallback;
    src: local('Asap Bold Italic'),
        url('asap-v21-latin-700italic.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('asap-v21-latin-700italic.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* khand-700 - latin */
@font-face {
    font-family: 'Khand';
    font-style: normal;
    font-weight: 700;
    font-display: fallback;
    src: local('Khand Bold'),
        url('khand-v12-latin-700.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('khand-v12-latin-700.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* pt-sans-regular - latin */
@font-face {
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 400;
    font-display: fallback;
    src: local('PT Sans Regular'),
        url('pt-sans-v16-latin-regular.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('pt-sans-v16-latin-regular.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* pt-sans-italic - latin */
@font-face {
    font-family: 'PT Sans';
    font-style: italic;
    font-weight: 400;
    font-display: fallback;
    src: local('PT Sans Italic'),
        url('pt-sans-v16-latin-italic.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('pt-sans-v16-latin-italic.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* pt-sans-700 - latin */
@font-face {
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 700;
    font-display: fallback;
    src: local('PT Sans Bold'),
        url('pt-sans-v16-latin-700.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('pt-sans-v16-latin-700.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* pt-sans-700italic - latin */
@font-face {
    font-family: 'PT Sans';
    font-style: italic;
    font-weight: 700;
    font-display: fallback;
    src: local('PT Sans Bold Italic'),
        url('pt-sans-v16-latin-700italic.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('pt-sans-v16-latin-700italic.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* montserrat-regular - latin */
@font-face {
    font-display: fallback;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: url('montserrat-v25-latin-regular.woff2') format('woff2');
  }

/* montserrat-italic - latin */
@font-face {
    font-display: fallback;
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 400;
    src: url('montserrat-v25-latin-italic.woff2') format('woff2');
}

/* montserrat-700 - latin */
@font-face {
    font-display: fallback;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    src: url('montserrat-v25-latin-700.woff2') format('woff2');
}

/* montserrat-700italic - latin */
@font-face {
    font-display: fallback;
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 700;
    src: url('montserrat-v25-latin-700italic.woff2') format('woff2');
}
