.c-webshareui {

    .web-share {
        position: fixed;
        top: -10px;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0);
        transition: background .4s;
        padding: 8px;
        flex-direction: column;
        justify-content: flex-end;
        z-index: 100000;
        display: none;
        font-size: rem(13);
    }

    .web-share-title {
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0.12em;
        opacity: 0.7;
        text-align: center;
    }

    .web-share-container {
        max-width: 490px;
        width: 100%;
        box-sizing: border-box;
        margin: 0 auto 8px;
        background: #F8F8F8;
        border-radius: 12px;
        box-shadow: rgba(0, 0, 0, 0.5) 0 2px 4px;
        padding: 16px 23px;
        text-align: center;
        color: black;
        transform: translateY(10vh);
        opacity: 0;
        transition: transform .4s, opacity .4s;
    }

    .web-share-container.web-share-cancel {
        color: #0076FF;
        font-size: 16px;
        text-align: center;
        cursor: pointer;
    }

    .web-share-item {
        margin: 16px 8px;
        display: inline-block;
        text-decoration: none !important;
        color: black !important;
        cursor: pointer;
        width: 64px;
    }

    .web-share-item[href=""] {
        display: none;
    }

    .web-share-item-desc {
        text-align: center;
        margin-top: 4px;
    }

    .web-share.desktop .web-share-sms {
        display: none;
    }
}