/* ==========================================================================
   Sharing component
   - article toolbar
   - Same CSS as what is used in the .c-sociallinks component. Consider using
   - .c-sociallinks for this instead to get rid of duplicate code?
   ========================================================================== */

.c-sharing {
    --icon-size: #{rem(20)};

    &--large {
        --icon-size: #{rem(30)};
    }

    &:after {
        content: "";
        display: table;
        clear: both;
    }

    ul {
        padding: 0;
        margin: rem(0 0 0 -8);
        list-style-type: none;
        display: flex;
        flex-wrap: wrap;
        width: fit-content;
        float: left;
    }

    a, button, &__button {
        display: flex;
        padding: rem(12) calc(var(--icon-size) * 0.4);
    }
}
