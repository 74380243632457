@import 'node_modules/video.js/dist/video-js.min';

.vjs-theme-aby {
    box-shadow: $box-shadow;
    color: cssvar(color-white);

    @include tpl(antenne) {
        color: cssvar(color-primary);
        border-radius: $global-radius;
    }
}

.vjs-theme-aby video,
.vjs-theme-aby .vjs-poster {
    @include tpl(antenne) {
        border-radius: $global-radius;
    }
}

.vjs-theme-aby .vjs-big-play-button {
    background: cssvar(color-primary);
    font-size: rem(48);
    top: 50%;
    left: 50%;
    margin-top: rem(-45);
    margin-left: rem(-55);
    color: cssvar(color-white);
}

.vjs-theme-aby .vjs-big-play-button > .vjs-icon-placeholder::before {
    line-height: rem(70);
}

.video-js:hover .vjs-big-play-button,
.vjs-theme-aby .vjs-big-play-button:hover,
.vjs-theme-aby .vjs-big-play-button:focus {
    color: cssvar(color-secondary);
    transition: background-color ease-in-out 0.2s;
}

.vjs-theme-aby .vjs-control-bar {
    height: rem(50);
    border-bottom-left-radius: calc($global-radius - 0.075rem);
    border-bottom-right-radius: calc($global-radius - 0.075rem);
    background-color: cssvar(color-background);

    @include tpl(rockantenne) {
        color: cssvar(color-white);
        background-color: cssvar(color-mediumblue);
    }
}

.vjs-theme-aby .vjs-control {
    &:hover {
        color: cssvar(color-secondary);
        transition: color ease-in-out 0.2s, background-color ease-in-out 0.2s;
    }
}

.vjs-theme-aby .vjs-button > .vjs-icon-placeholder::before {
    line-height: rem(50);
}

.vjs-theme-aby .vjs-time-control {
    font-size: rem(14);
    display: block;
    min-width: rem(20);
    line-height: rem(50);
    display: flex;
    align-items: center;
    justify-content: center;
}

.vjs-theme-aby .vjs-current-time {
    order: 4;
    display: flex;
    padding-right: 0;
}

.vjs-theme-aby .vjs-duration {
    padding-left: 0;
    display: flex;
    order: 6;
}

.vjs-theme-aby .vjs-time-divider {
    order: 5;
    display: flex;
    padding-left: 0;
    padding-right: 0;

    & span {
        line-height: rem(50);
    }
}

/* Play Button */
.vjs-theme-aby .vjs-play-control {
    order: 1;
    display: flex;
    font-size: 1.5em;
}

.vjs-theme-aby .vjs-volume-level {
    background-color: cssvar(color-primary);
    border-radius: $global-radius;
}

.vjs-theme-aby .vjs-seek-button-back {
    display: none;
    order: 2;

    @include breakpoint(medium) {
        display: flex;
    }
}

.vjs-theme-aby .vjs-seek-button-forward {
    display: none;
    order: 3;

    @include breakpoint(medium) {
        display: flex;
    }
}

.vjs-theme-aby .vjs-seek-button {
    // & .skip-back {
        // order: 2;
    // }

    & .skip-forward {
        order: 3;
    }

    & svg {
        fill: none;
        max-height: 100%;
        max-width: 100%;
        transition: stroke ease-in-out 0.2s;
        stroke: cssvar(color-primary);

        @include tpl(rockantenne) {
            stroke: cssvar(color-white);
        }

        @media (hover: hover) {
            &:hover {
                stroke: cssvar(color-secondary);
            }
        }
    }
}

.vjs-theme-aby .vjs-volume-panel {
    display: none;
    order: 8;

    @include breakpoint(medium) {
        display: flex;
    }
}

.vjs-theme-aby .vjs-progress-control {
    order: 7;
    display: flex;
}

.vjs-theme-aby .vjs-progress-holder {
    background-color: cssvar(color-lightgray);
    border-radius: $global-radius;
}

.vjs-theme-aby .vjs-play-control .vjs-icon-placeholder::before {
    color: cssvar(color-primary);

    @include tpl(rockantenne) {
        color: cssvar(color-white);
    }
}

.vjs-theme-aby .vjs-play-control:hover .vjs-icon-placeholder::before {
    color: cssvar(color-secondary);
    transition: color ease-in-out 0.2s, background-color ease-in-out 0.2s;
}

.vjs-theme-aby .vjs-slider-horizontal .vjs-volume-level:before {
    top: -0.4rem;
}

.vjs-theme-aby .vjs-load-progress,
.vjs-theme-aby .vjs-load-progress div {
    background-color: cssvar(color-mediumgray);
    border-radius: $global-radius;
}

.vjs-theme-aby .vjs-play-progress {
    @include tpl(antenne) {
        border-radius: $global-radius;
        background-color: cssvar(color-primary);
    }

    @include tpl(rockantenne) {
        background-color: cssvar(color-white);
    }
}

.vjs-theme-aby .vjs-play-progress::before {
    display: none;
    // height: 0.8em;
    // width: 0.8em;
    // content: '';
    // background-color: cssvar(color-primary);
    // border-radius: 0.8em;
    // top: -0.25em;
}

.vjs-theme-aby svg {
    max-height: 100%;
    max-width: 100%;
}

.vjs-theme-aby .vjs-airplay-control {
    display: none;
    order: 9;

    @include breakpoint(medium) {
        display: flex;
    }

    & svg {
        padding: rem(10);
    }
}

.vjs-theme-aby .vjs-fullscreen-control {
    order: 10;

    & svg {
        padding: rem(12);
    }

    & svg[data-icon-minimize] {
        display: none;
    }

    &.is-fullscreen {
        & svg[data-icon-maximize] {
            display: none;
        }

        & svg[data-icon-minimize] {
            display: block;
        }
    }
}
