.c-tooltip {
    position: relative;
    z-index: 12;
    height: rem(20);


    >button {
        display: block;
        margin: rem(-5);
        height: rem(30);
        padding: rem(1 6);
    }

    &__icon--dots {
        display: block;
        width: rem(19);
        height: 100%;
        margin: 0 auto;
    }

    &__content {
        display: none;
        flex-direction: column;
        justify-content: space-between;
        padding: rem(10 15);
        border-radius: $global-radius;
        background-color: cssvar(color-primary);
        color: cssvar(color-white);
        z-index: 11;

        @include tpl(rockantenne) {
            background-color: cssvar(color-darkblue);
        }

        @include tpl(oldieantenne) {
            background-color: cssvar(color-black);
        }

        button {
            display: flex;
            width: 100%;
            height: 100%;
            margin: 0;
            padding: rem(5 0);
            color: currentColor;
            white-space: nowrap;

            &:hover {
                .c-tooltip__icon--dots {
                    fill: cssvar(color-secondary);
                }
            }
        }

        &[data-show] {
            display: flex;
        }
    }

    &__arrow {
        visibility: hidden;

        &::before {
            visibility: visible;
            content: '';
            transform: rotate(45deg);
        }
    }

    &__arrow,
    &__arrow::before {
        position: absolute;
        width: rem(8);
        height: rem(8);
        background: inherit;
    }

    //tooltip offset
    &__content[data-popper-placement^='top']>&__arrow {
        bottom: -4px;
    }

    &__content[data-popper-placement^='bottom']>&__arrow {
        top: -4px;
    }

    &__content[data-popper-placement^='left']>&__arrow {
        right: -4px;
    }

    &__content[data-popper-placement^='right']>&__arrow {
        left: -4px;
    }
}
