/* ==========================================================================
   Audioplayer component found in footer of websites
   - Component styles
   - Own grid styles - the utility grid did not appropriately position
     elements during iOS testing and therefore it was decided to make own CSS.
   ========================================================================== */


.c-player {
    view-transition-name: player;
    position: fixed;
    z-index: 100;
    top: auto;
    bottom: rem(10);
    right: rem(9);
    left: rem(9);
    height: rem(64);
    color: cssvar(color-primary);
    background: cssvar(color-secondary);
    box-shadow: 0 0 rem(16) rgba(#000, 0.3);
    border-radius: cssvar(border-radius);

    @include tpl(rockantenne) {
        background: cssvar(color-bluegray);
        color: cssvar(color-darkblue);
    }

    @include tpl(oldieantenne) {
        @include setcssvars((
            color-primary: #000,
            color-secondary: cssvar(color-white),

        ));
    }

    a {
        display: flex;
    }

    &__icon {
        // Icon defaults
        margin-inline: rem(10);
        transition: color .03s ease-in-out;
    }

    &__buttons {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__progress {
        display: none;
    }

    &__seekbutton {
        display: none;
    }

    &__error {
        color: cssvar(color-alert);
        font-weight: normal;
    }

    &__image-wrapper {
        height: rem(64);
        width: rem(64);
        overflow: hidden;
        border-radius: cssvar(border-radius) 0 0 cssvar(border-radius);
    }

    &__image {
        position: relative;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: cssvar(border-radius) 0 0 cssvar(border-radius);

        &::before {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: cssvar(color-secondary);
        }
    }

    &__currenttitle {
        font-size: rem(14);
        font-weight: bold;
        margin-bottom: rem(-2);
        white-space: nowrap;

        @include tpl (rockantenne) {
            font-family: cssvar(header-font-family);
            letter-spacing: cssvar(header-font-spacing);
        }

        @include breakpoint(medium) {
            font-size: rem(18);
            margin-bottom: rem(0);
        }
    }

    &__currentartist {
        margin: 0;
        font-size: rem(14);
        white-space: nowrap;
        display: inline-block;

        @include breakpoint(medium) {
            font-size: inherit;
        }
    }

    &__station {
        font-size: rem(14);
        font-weight: bold;
        margin-bottom: rem(0);

        @include breakpoint(medium) {
            font-size: rem(18);
        }
    }

    &.has-error {
        @include breakpoint(medium, down) {

            .c-player__main-actions {
                flex: 1;
                max-width: inherit;
            }
        }

        .c-playbutton, .l-playergrid__cell--bookmark {
            display: none;
        }

        .c-player__currenttitle {
            color: cssvar(color-alert);
            font-weight: 400;
        }
    }

    // Controls area

    &__controls {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        button {
            display: flex;
            align-items: center;
        }

        &--change-radio.c-button { // needs the direct connection to c-button to activate padding
            padding: rem(6) rem(16);
            animation: fadeIn 0.5s linear;
            font-size: rem(14);
            white-space: nowrap;
            margin: 0 0 0 auto;
            max-width: fit-content;
        }

        &__airplay {
            color: cssvar(color-primary);
            fill: cssvar(color-primary);

            button:disabled {
                opacity: calc(50%);
                cursor: not-allowed;
            }
        }

        &__volume-control {

            input[type=range]::-moz-range-track {
                appearance: none;
                width: 100%;
                height: 3px;
                border-radius: rem(8);
                background-color: rgba(255, 255, 255, 0.2);
            }

            .c-tooltip__content {
                padding: rem(4);
                width: rem(45);
                margin-left: rem(6);
                min-height: rem(120);
                animation: fadeIn 0.1s linear;
                box-shadow: 0px 0px 12px 0px rgba(255, 255, 255, 0.15);

                &[data-show] {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-items: center;
                    justify-content: center;
                }
            }
        }

        &__range-slider {
            cursor: pointer;
            transform: rotate(-90deg);
            appearance: none;
            background: transparent;
            height: rem(5);
            max-width: rem(100);
            border-radius: rem(4);
            background-image: linear-gradient(cssvar(color-white), cssvar(color-white));
            background-size: 67% 100%;
            background-repeat: no-repeat;
        }

        &__range-slider::-webkit-slider-thumb {
            appearance: none;
            height: 14px;
            width: 14px;
            margin-top: rem(-6);
            border-radius: 50%;
            background: cssvar(color-white);
            border: 1px solid cssvar(color-white);
            cursor: pointer;
            box-shadow: 1px 1px 1px 0 cssvar(color-black);
        }

        &__range-slider::-moz-range-thumb {
            appearance: none;
            height: 12px;
            width: 12px;
            margin-top: rem(-6);
            border-radius: 50%;
            background: cssvar(color-white);
            border: 1px solid cssvar(color-white);
            cursor: pointer;
            box-shadow: 1px 1px 1px 0 cssvar(color-black);
        }

        &__range-slider::-ms-thumb {
            appearance: none;
            height: 14px;
            width: 14px;
            margin-top: rem(-6);
            border-radius: 50%;
            background: cssvar(color-white);
            border: 1px solid cssvar(color-white);
            cursor: pointer;
            box-shadow: 1px 1px 1px 0 cssvar(color-mediumblue);
        }

        &__range-slider::-webkit-slider-runnable-track {
            appearance: none;
            width: 100%;
            height: 3px;
            border-radius: rem(8);
            background-color: rgba(255, 255, 255, 0.2);
        }

        &__range-slider::-ms-track {
            appearance: none;
            width: 100%;
            height: 3px;
            border-radius: rem(8);
            background-color: rgba(255, 255, 255, 0.2);
        }


    } // End of controls area

    // Hover effects
    // This crazy selection is an override for the crazy c-button styling
    .c-button:focus:not(:focus-visible).has-outline, .c-player__icon, button, button:focus:not(:focus-visible):hover {
        color: cssvar(color-primary);
        border-color: cssvar(color-primary);
        cursor: pointer;

        @include tpl(antenne,oldieantenne) {
            // No color change here for ABY
            &:hover {
                color: cssvar(color-primary);
                border-color: cssvar(color-primary);
            }
        }

        @include tpl(rockantenne) {
            color: cssvar(color-darkblue);

            @media (pointer: fine) {
                &:hover {
                    color: cssvar(color-secondary);
                }
            }
        }
    }

    // Tooltip edge case helper. Tooltip component is needed but our spacing here is different.
    .c-tooltip {
        height: auto;

        > button {
            display: flex;
            height: auto;
        }
    }

    &.has-progressbar {
        @include breakpoint(large) {
            .c-player__seekbutton {
                display: flex;

                &:hover svg {
                    // Hover effects only for rockantenne
                    @include tpl(rockantenne) {
                        stroke: cssvar(color-secondary);
                        transition: stroke ease-in-out 0.2s;
                    }
                }

                svg {
                    fill: none;
                    stroke: currentColor;
                }
            }

            .c-playbutton--player svg {
                height: rem(24);
                width: rem(24);
            }

            .c-player__progress {
                display: block;
                margin-top: rem(-6);

                input[type=range]::-moz-range-track {
                    appearance: none;
                    width: 100%;
                    height: 5px;
                    border-radius: rem(8);
                    background-color: rgba(15, 23, 42, 0.08);
                }

                &__durations {
                    display: flex;
                    justify-content: space-between;
                    font-size: rem(13);
                    margin-bottom: rem(-6);

                    p {
                        margin: 0 rem(2) rem(5);
                        line-height: rem(8);
                    }
                }

                &__range-slider {
                    width: 100%;
                    cursor: pointer;
                    appearance: none;
                    background: transparent;
                    height: 5px;
                    border-radius: $global-radius;
                    background-image: linear-gradient(cssvar(color-primary), cssvar(color-primary));
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    transition: background-size 1s linear;

                    &.u-quick-transition {
                        transition: background-size 0.15s linear;
                    }
                }

                &__range-slider::-webkit-slider-thumb {
                    appearance: none;
                    height: 10px;
                    width: 10px;
                    margin-top: -3px;
                    border-radius: 50%;
                    background: transparent;
                    cursor: pointer;
                }

                &__range-slider::-moz-range-thumb {
                    appearance: none;
                    height: 10px;
                    width: 10px;
                    margin-top: -3px;
                    border-radius: 50%;
                    background: transparent;
                    cursor: pointer;
                }

                &__range-slider::-ms-thumb {
                    appearance: none;
                    height: 10px;
                    width: 10px;
                    margin-top: -3px;
                    border-radius: 50%;
                    background: transparent;
                    cursor: pointer;
                }

                &__range-slider::-webkit-slider-runnable-track {
                    appearance: none;
                    width: 100%;
                    height: 5px;
                    border-radius: rem(8);
                    background-color: rgba(15, 23, 42, 0.08);
                }

                &__range-slider::-ms-track {
                    appearance: none;
                    width: 100%;
                    height: 5px;
                    border-radius: rem(8);
                    background-color: rgba(15, 23, 42, 0.08);
                }
            }
        }
    }
}


// Layout CSS
.l-playergrid {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-left: calc(cssvar(grid-gutter)/-2);
    margin-right: calc(cssvar(grid-gutter)/-2);
    padding-right: rem(8);

    &__cell {
        flex: 0 0 auto;
        order: var(--order, 0);
        margin-left: calc(cssvar(grid-gutter)/2);
        margin-right: calc(cssvar(grid-gutter)/2);

        &--auto {
            flex: 1 1 0;
            width: auto;
        }

        &--bookmark {
            flex: 0 1 auto;
            max-width: rem(40);
            svg {
                width: rem(20);
                height: rem(20);
            }

            @include breakpoint (medium) {
                margin-left: 0;
                margin-right: 0;
            }
        }

        &--playbutton {
            flex: 0 1 auto;
            max-width: rem(35);

            @include breakpoint (medium) {
                flex: 1 1 auto;
                max-width: inherit;
            }
        }

        @each $breakPointName,
        $breakpointValue in $breakpoints {
            @include breakpoint($breakPointName) {
                order: var(--#{$breakPointName}-order, var(--order));
                @include breakpointCell($breakPointName);
            }
        }

        &--currentlyplaying {
            order: 3;
            overflow: hidden;

            @include breakpoint (large, down) {
                flex: 1 1 auto;
            }

            @include breakpoint (medium, down) {
                order: 0;
            }
        }
    }
}
