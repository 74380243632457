/* ==========================================================================
   Wall Component
   - Used in combination with the dialog component
   - RegWall and XtraWall chunks and content blocks in MODX
   ========================================================================== */


.c-wall {
    // Basic layout features
    display: flex;
    position: relative;
    flex-direction: column;

    &__logo {
        width: 100%;
        max-width: rem(83);
    }

    &__keyvisual .c-slider__track {
        // prevent scroll issues with full-heigh slider
        padding-bottom: 0;
    }

    @include breakpoint(large) {

        &__header,
        &__footer {
            flex: 1 0 auto;
            justify-content: flex-end;
            width: 50%;
            margin-left: 50%;
        }

        &__keyvisual {
            position: absolute;
            top: 0;
            left: 0;
            width: 50%;
            height: 100%;
        }
    }

    //------------- Totkenhunt Content
    &--tokenhunt {
        padding: cssvar(grid-gutter);
        img, .c-image, .c-image picture {
            max-width: rem(200);
            background-color: transparent;
            small:not(.c-image__flag) {
                display: none;
            }
        }
    }

    //------------- Regwall Content
    &--regwall {
        max-height: rem(640); // needed for small screen design

        .c-wall {

            // do not add any styles here for directly the .c-wall class, only children.
            // Otherwise incorrect css will be written.
            &__header {
                h2 {
                    margin: 0;
                    font-size: rem(24);

                    @include breakpoint(large) {
                        font-size: rem(32);
                    }
                }
            }

            &__logo {
                margin: rem(15) auto;

                @include breakpoint(large) {
                    max-width: rem(154);
                    margin: rem(50) auto rem(80);
                }
            }

            &__bg {
                posfition: absolute;
                z-index: -1;
                right: 0;
                bottom: 0;
                left: 0;
                height: 100%;

                img {
                    height: 100%;
                    width: 100%;
                    transform: scaleX(-1);

                    @include tpl(rockantenne,oldieantenne) {
                        object-fit: cover;
                    }

                    @include breakpoint(large) {
                        width: 50%;
                        transform: initial;
                    }
                }
            }

            &__footer {
                small {
                    display: block;
                    margin-bottom: $global-margin;
                }
                @include breakpoint(large) {
                    small {
                        margin: rem(100) 0 0;
                    }
                }
            }
        }

        @include tpl(antenne){
            // Button - we have a blue colored background for ABY which needs a different hover color.
            .c-button--secondary {
                --button-hovercolor: #{cssvar(color-primary)};
                --button-hoverbackgroundcolor: #{cssvar(color-white)}
            }
        }

        @include breakpoint(large) {
            // Needed for height of regwall because keyvisual div is absolute positioned here
            min-height: rem(545);
        }
    }

    //------------- SiteSelect content (RA Websites)
    &--stationselect {

        // Logo
        img {
            max-width: rem(150);
            margin: rem(-67) auto auto;
        }
    }
}
