$transitions: color 0.3s ease-in-out, background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

.c-button {
    display: flex;
    place-content: center;
    align-items: center;
    max-width: var(--button-maxwidth, none);
    width: var(--button-width, fit-auto);
    margin-bottom: $global-margin;
    padding: em(10 18);
    font-family: cssvar(header-font-family);
    letter-spacing: cssvar(header-font-spacing);
    font-weight: $global-weight-bold;
    color: var(--button-color, #fff);
    background-color: var(--button-backgroundcolor, magenta);
    border-radius: $global-radius;
    cursor: pointer;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transition: $transitions;
    box-shadow: inset 0 0 0 2px var(--button-border-color, transparent);

    // @include tpl(rockantenne) {
    //     // clip-path for RA tpl
    //     padding: rem(12 18 8);
    //     border-radius: unset;
    //     clip-path: polygon(12px 0%, 100% 0%, calc(100% - 12px) 100%, 0% 100%);
    // }

    //the border property does not recognize clip-path, so we need a work around for RA:
    //an added pseudo element with the same clip path & a white background that lays over the btn-background and under the text
    @include tpl(rockantenne) {
        position: relative;
        box-shadow: none;
        background: transparent;
        clip-path: polygon(0px 0, calc(100% + 20px) 0, 100% 100%, -20px 100%);
        padding: rem(12 18 8);
        border-radius: unset;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 4px;
            right: 4px;
            inset: 0;
            background-color: var(--button-backgroundcolor, transparent);
            box-shadow: inset 0 0 0 2px var(--button-border-color, transparent);
            // clip-path: polygon(12px 0%, 100% 0%, calc(100% - 12px) 100%, 0% 100%);
            transform: skewX(-12deg);
            z-index: -1;
            transition: $transitions;
        }
    }

    // additional `html body` selector needed to fix specificity with `tpl()` functions
    html body & {
        // Focus and hover effects only for devices with fine pointers. This excludes touch devices.
        // Touch devices keep focus style after they have been used and do not reset back to original state.
        @media (pointer: fine) {
            &:focus:not(:focus-visible) {
                --button-border-color: var(--button-hover-backgroundcolor);
            }
            &:focus:not(:focus-visible):hover,
            &:hover,
            &:focus-visible {
                --button-color: var(--button-hover-color, #fff) !important;
                --button-backgroundcolor: var(--button-hover-backgroundcolor, #000) !important;
            }
        }
    }

    &--centered {
        margin-inline: auto;
    }

    &.is-loading {
        pointer-events: none;
        cursor: wait;
        &:after {
            content: "";
            display: block;
            width: rem(22);
            height: rem(22);
            margin-left: rem(10);
            background: currentColor;
            border-radius: 50%;
            transform: scale(1);
            animation: heartbeat 2s infinite;
        }
    }

    &--textleft {
        place-content: start;
        text-align: left;
    }

    &.disabled {
        pointer-events: none;
        opacity: 0.5;
        cursor: not-allowed;
    }

    &.has-outline {
        color: var(--button-color, magenta);
        background-color: var(--button-backgroundcolor, transparent);
        --button-border-color: magenta;
    }

    &__icon {
        width: rem(16);
        height: rem(16);
        margin-right: rem(7);

        @include tpl(rockantenne) {
            margin-bottom: rem(4);
        }
    }

    &--inline, html.template--rockantenne &--inline {
        padding: 0;
        clip-path: unset;
    }

    &--fullwidth {
        width: 100%;

        @include tpl(rockantenne) {
            clip-path: polygon(3% 0%, 100% 0%, 97% 100%, 0% 100%);

            &.has-outline {
                &::before {
                    clip-path: polygon(3% 0%, 100% 0%, 97% 100%, 0% 100%);
                }
            }
        }
    }

    &--large {
        font-size: rem(21);
    }

    &--fullwidth--small {
        @include breakpoint(medium, down) {
            width: 100%;
        }
    }

    @media print {
        background: none;
        color: #000;
        border: 1px solid #000000;
        box-shadow: none;
    }

    &--narrow {
        padding: rem(5 15) !important;
    }
}

.c-button--corners {
    @include tpl(antenne) {
        border-radius: rem(6) 0 rem(6) 0;
    }

    @include tpl(rockantenne) {
        clip-path: polygon(4% 0, 100% 0%, 96% 100%, 0% 100%);
    }
}

//button resets
button {
    background-color: transparent;
    border: none;
    cursor: pointer;
}

// heart bookmark buttons
button[data-bookmark]:hover svg {
    animation: wobbleScale 1s ease;
}

.c-button {
    &, &--primary {
        @include tpl(antenne, oldieantenne) {
            --button-color: #{cssvar(color-background)};
            --button-backgroundcolor: #{cssvar(color-primary)};

            --button-hover-color: #{cssvar(color-primary)};
            --button-hover-backgroundcolor: #{cssvar(color-secondary)};

            &.has-outline {
                --button-color: #{cssvar(color-primary)};
                --button-backgroundcolor: transparent;

                --button-hover-color: #{cssvar(color-background)};
                --button-hover-backgroundcolor: #{cssvar(color-primary)};

                --button-border-color: #{cssvar(color-primary)};
            }

            &.is-active { // active has no different colors for hover - expected!
                --button-color: #{cssvar(color-primary)};
                --button-backgroundcolor: #{cssvar(color-secondary)};

                --button-hover-color: #{cssvar(color-primary)};
                --button-hover-backgroundcolor: #{cssvar(color-secondary)};
            }
        }

        @include tpl(rockantenne) {
            --button-color: #{cssvar(color-white)};
            --button-backgroundcolor: #{cssvar(color-primary)};

            --button-hover-color: #{cssvar(color-white)};
            --button-hover-backgroundcolor: #{cssvar(color-secondary)};

            &.has-outline {
                --button-color: #{cssvar(color-white)};
                --button-backgroundcolor: transparent;

                --button-hover-color: #{cssvar(color-white)};
                --button-hover-backgroundcolor: #{cssvar(color-primary)};

                --button-border-color: #{cssvar(color-primary)};
            }

            &.is-active { // active has no different colors for hover - expected!
                --button-color: #{cssvar(color-white)};
                --button-backgroundcolor: #{cssvar(color-secondary)};

                --button-hover-color: #{cssvar(color-white)};
                --button-hover-backgroundcolor: #{cssvar(color-secondary)};
            }
        }
    }

    &--secondary {
        @include tpl(antenne, oldieantenne) {
            --button-color: #{cssvar(color-primary)};
            --button-backgroundcolor: #{cssvar(color-secondary)};

            --button-hover-color: #{cssvar(color-background)};
            --button-hover-backgroundcolor: #{cssvar(color-primary)};

            &.has-outline {
                --button-color: #{cssvar(color-secondary)};
                --button-backgroundcolor: transparent;

                --button-hover-color: #{cssvar(color-primary)};
                --button-hover-backgroundcolor: #{cssvar(color-secondary)};

                --button-border-color: #{cssvar(color-secondary)};
            }
        }

        @include tpl(oldieantenne) {
            --button-hover-color: #{cssvar(color-secondary)};
        }

        @include tpl(rockantenne) {
            --button-color: #{cssvar(color-white)};
            --button-backgroundcolor: #{cssvar(color-secondary)};

            --button-hover-color: #{cssvar(color-white)};
            --button-hover-backgroundcolor: #{cssvar(color-primary)}; // was mediumgray before

            &.has-outline {
                --button-color: #{cssvar(color-secondary)};
                --button-backgroundcolor: transparent

                --button-hover-color: #{cssvar(color-white)};
                --button-hover-backgroundcolor: #{cssvar(color-secondary)};

                --button-border-color: #{cssvar(color-secondary)};
            }
        }
    }

    &--white {
        @include tpl(antenne, oldieantenne) {
            --button-color: #{cssvar(color-primary)};
            --button-backgroundcolor: #{cssvar(color-white)};

            --button-hover-color: #{cssvar(color-background)};
            --button-hover-backgroundcolor: #{cssvar(color-primary)};

            &.has-outline {
                --button-color: #{cssvar(color-white)};
                --button-backgroundcolor: transparent;

                --button-hover-color: #{cssvar(color-primary)};
                --button-hover-backgroundcolor: #{cssvar(color-white)};

                --button-border-color: #{cssvar(color-white)};
            }
        }

        @include tpl(oldieantenne) {
            --button-color: #{cssvar(color-background)};
            --button-hover-color: #{cssvar(color-white)};
            --button-hover-backgroundcolor: #{cssvar(color-secondary)};

            &.has-outline {
                --button-color: #{cssvar(color-white)};
                --button-hover-color: #{cssvar(color-background)};
                --button-hover-backgroundcolor: #{cssvar(color-white)};
            }
        }

        @include tpl(rockantenne) {
            --button-color: #{cssvar(color-primary)};
            --button-backgroundcolor: #{cssvar(color-white)};

            --button-hover-color: #{cssvar(color-white)};
            --button-hover-backgroundcolor: #{cssvar(color-primary)};

            &.has-outline {
                --button-color: #{cssvar(color-white)};
                --button-backgroundcolor: transparent;

                --button-hover-color: #{cssvar(color-background)};
                --button-hover-backgroundcolor: #{cssvar(color-white)};

                --button-border-color: #{cssvar(color-white)};
            }
        }
    }

    &--transparent {
        @include tpl(antenne, oldieantenne) {
            --button-color: #{cssvar(color-primary)};
            --button-backgroundcolor: transparent;

            --button-hover-color: #{cssvar(color-secondary)};
            --button-hover-backgroundcolor: transparent;

            --button-border-color: transparent;
        }
        @include tpl(rockantenne) {
            --button-color: #{cssvar(color-white)};
            --button-backgroundcolor: transparent;

            --button-hover-color: #{cssvar(color-secondary)};
            --button-hover-backgroundcolor: transparent;

            --button-border-color: transparent;
        }
    }

    body &--alert.c-button { // longer selector needed here to really select all template variations
        --button-color: #{cssvar(color-white)};
        --button-backgroundcolor: #{cssvar(color-alert)};

        --button-hover-color: #{cssvar(color-alert)};
        --button-hover-backgroundcolor: #{cssvar(color-white)};

        --button-border-color: #{cssvar(color-alert)};

        &.has-outline {
            --button-color: #{cssvar(color-alert)};
            --button-backgroundcolor: transparent;

            --button-hover-color: #{cssvar(color-background)};
            --button-hover-backgroundcolor: #{cssvar(color-alert)};

            --button-border-color: #{cssvar(color-alert)};

            @include tpl(rockantenne,oldieantenne) {
                --button-hover-color: #{cssvar(color-white)};
            }
        }
    }

    body &--gray.c-button { // longer selector needed here to really select all template variations
        --button-color: #{cssvar(color-primary)};
        --button-backgroundcolor: #{cssvar(color-lightbluegray, cssvar(color-lightestgray))};

        --button-hover-color: #{cssvar(color-background)};
        --button-hover-backgroundcolor: #{cssvar(color-primary)};

        @include tpl(oldieantenne) {
            --button-color: #{cssvar(color-background)};
            --button-hover-color: #{cssvar(color-white)};
            --button-hover-backgroundcolor: #{cssvar(color-secondary)};
        }

        @include tpl(rockantenne) {
            --button-hover-color: #{cssvar(color-white)};
        }

        &.has-outline {
            --button-color: #{cssvar(color-lightbluegray, cssvar(color-lightestgray))};
            --button-backgroundcolor: transparent;

            --button-hover-color: #{cssvar(color-primary)};
            --button-hover-backgroundcolor: #{cssvar(color-lightbluegray, cssvar(color-lightestgray))};

            --button-border-color: #{cssvar(color-lightbluegray, cssvar(color-lightestgray))};

            @include tpl(rockantenne) {
                --button-hover-color: #{cssvar(color-primary)};
            }

            @include tpl(oldieantenne) {
                --button-color: #{cssvar(color-lightbluegray, cssvar(color-lightestgray))};
                --button-hover-color: #{cssvar(color-background)};
                --button-hover-backgroundcolor: #{cssvar(color-lightbluegray, cssvar(color-lightestgray))};
            }
        }
    }

    body &--mediumgray.c-button { // longer selector needed here to really select all template variations
        --button-color: #{cssvar(color-white)};
        --button-backgroundcolor: #{cssvar(color-mediumgray)};

        --button-hover-color: #{cssvar(color-background)};
        --button-hover-backgroundcolor: #{cssvar(color-primary)};

        @include tpl(oldieantenne) {
            --button-hover-color: #{cssvar(color-white)};
            --button-hover-backgroundcolor: #{cssvar(color-secondary)};
        }

        @include tpl(rockantenne) {
            --button-hover-color: #{cssvar(color-white)};
        }

        &.has-outline {
            --button-color: #{cssvar(color-mediumgray)};
            --button-backgroundcolor: transparent;

            --button-hover-color: #{cssvar(color-white)};
            --button-hover-backgroundcolor: #{cssvar(color-mediumgray)};

            --button-border-color: #{cssvar(color-mediumgray)};

            @include tpl(oldieantenne) {
                --button-hover-backgroundcolor: #{cssvar(color-mediumgray)};
            }
        }
    }

    body &--darkgray.c-button { // longer selector needed here to really select all template variations
        --button-color: #{cssvar(color-white)};
        --button-backgroundcolor: #{cssvar(color-darkgray)};

        --button-hover-color: #{cssvar(color-background)};
        --button-hover-backgroundcolor: #{cssvar(color-primary)};

        @include tpl(oldieantenne) {
            --button-hover-color: #{cssvar(color-white)};
            --button-hover-backgroundcolor: #{cssvar(color-secondary)};
        }

        @include tpl(rockantenne) {
            --button-hover-color: #{cssvar(color-white)};
        }

        &.has-outline {
            --button-color: #{cssvar(color-darkgray)};
            --button-backgroundcolor: transparent;

            --button-hover-color: #{cssvar(color-white)};
            --button-hover-backgroundcolor: #{cssvar(color-darkgray)};

            --button-border-color: #{cssvar(color-darkgray)};

            @include tpl(oldieantenne) {
                --button-hover-backgroundcolor: #{cssvar(color-darkgray)};
            }
        }
    }

    body &--darkblue.c-button { // longer selector needed here to really select all template variations
        --button-color: #{cssvar(color-white)};
        --button-backgroundcolor: #{cssvar(color-darkblue)};

        --button-hover-color: #{cssvar(color-background)};
        --button-hover-backgroundcolor: #{cssvar(color-primary)};

        @include tpl(oldieantenne) {
            --button-hover-color: #{cssvar(color-white)};
            --button-hover-backgroundcolor: #{cssvar(color-secondary)};
        }

        @include tpl(rockantenne) {
            --button-hover-color: #{cssvar(color-white)};
        }

        &.has-outline {
            --button-color: #{cssvar(color-darkblue)};
            --button-backgroundcolor: transparent;

            --button-hover-color: #{cssvar(color-white)};
            --button-hover-backgroundcolor: #{cssvar(color-darkblue)};

            --button-border-color: #{cssvar(color-darkblue)};

            @include tpl(oldieantenne) {
                --button-hover-backgroundcolor: #{cssvar(color-darkblue)};
            }
        }
    }
}
