/* ==========================================================================
   Toolbar Component
   - Used in the article above the content and inside of audio widgets
   ========================================================================== */

.c-toolbar {
    display: flex;
    margin: rem(-10 0) $global-margin rem(-7);
    padding: 0;
    align-items: center;

    button,
    a {
        display: flex;
        padding: rem(9 6);
        min-width: rem(26); // promise a min width for a button, so that it does not squish to a non-touchable size

        &:has(span) {
            gap: rem(8);
        }
    }

    // Variation for article page toolbar
    &--article {
        margin-inline: unset;
        margin-bottom: calc($global-margin - 12px);

        p {
            margin: rem(0 8 0 18);
        }
    }

    // ----- Audiocard variation
    // Allow wrapping which keep buttons at a size that is easier to touch
    &--audiocard {
        flex-wrap: wrap;
        margin-block: rem(-3px);
    }

    // Column settings used in the article toolbar
    &__col {
        display: flex;
        flex: 1 1 auto;
        align-items: center;
    }

    &__col--align-right {
        justify-content: flex-end;
    }
}
