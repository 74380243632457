.c-accordion {
    input {
        position: absolute;
        opacity: 0;
        z-index: -1;
    }

    &__item {
        width: 100%;
        margin-bottom: rem(15);
        overflow: hidden;
        box-shadow: $box-shadow;

        @include tpl(antenne) {
            border-radius: $global-radius;
        }

        @include tpl(rockantenne) {
            background-color: cssvar(color-mediumblue);
        }

        @include tpl(oldieantenne) {
            background-color: cssvar(color-darkgray);
        }
    }

    &__label {
        display: flex;
        padding: rem(15);
        align-items: center;
        cursor: pointer;
        line-height: 1.3;

        >p {
            margin: 0;
        }

        svg {
            margin-lefT: auto;
        }
    }

    .c-icon--chevron {
        width: rem(15);
        height: rem(10);
        transition: transform .35s;
    }

    &__content {
        box-sizing: content-box;
        max-height: 0;
        overflow: hidden;
        transition: max-height var(--duration, 0.3) linear;

        @include tpl(antenne) {
            color: cssvar(color-primary);
            background-color: cssvar(color-white);
        }

        @include tpl(rockantenne) {
            color: cssvar(color-white);
            background-color: cssvar(color-mediumblue);
        }

        &inner {
            padding: rem(15 15 1 43);
        }
    }

    // :checked
    input:checked {
        +.c-accordion__label {
            .c-icon {
                transform: rotate(-180deg);
            }
        }

        ~.c-accordion__content {
            max-height: calc(var(--max-height, 2000) * 0.0625rem);
        }
    }
}
