/* ==========================================================================
   Cover Component
   - Used in various use cases
   ========================================================================== */

.c-cover {
    position: relative;
    aspect-ratio: 1 / 1;
    box-shadow: $box-shadow;
    overflow: hidden;
    color: cssvar(color-white);
    border-radius: $global-radius;

    @include tpl(oldieantenne) {
        border: rem(2) solid cssvar(color-primary);
    }

    img {
        width: 100%;
        height: auto;
        object-fit: cover;
        aspect-ratio: 1 / 1;

        &::before {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            inset: 0;
            background: cssvar(color-secondary);
        }
    }

    // Buttons and button area

    &__button {
        position: absolute;
        color: cssvar(color-white);
        z-index: 11;
    }

    &__icon {
        width: rem(22);
        height: rem(22);

        &:hover {
            fill: cssvar(color-secondary);
            cursor: pointer;
        }
    }

    &__button--play {
        opacity: 0; // Play button only shown on hover
        pointer-events: none;
        transition: opacity 0.2s ease-in-out;
        bottom: rem(8);
        right: rem(4);

        svg {
            height: rem(35);
            width: rem(35);

            @include breakpoint(medium) {
                height: rem(45);
                width: rem(45);
            }
        }
    }

    // Play button shown here on hover
    &:hover {
        .c-cover__button--play {
            opacity: 1;
            pointer-events: all;
        }
    }

    &__button--favorite {
        top: 0;
        right: 0;
        padding: rem(6 8);

        svg {
            width: rem(22);
            height: rem(22);
            filter: drop-shadow(0px 0px 1px rgb(0 0 0 / 40%)); // needed if background behind icon is white
        }

        &:not([data-bookmark]) {
            pointer-events: none;
        }
    }

    .c-tooltip {
        position: absolute;
        bottom: rem(5);
        left: rem(5);

        @include breakpoint(medium, down) {
            display: none;
        }
    }
}
