.c-songvoting {
    margin-bottom: $global-margin;

    .c-form__inner {
        padding-top: 1.75rem;

        + .c-form {
            margin-top: rem(-10);
        }
    }

    img {
        @include tpl(antenne) {
            border-radius: $global-radius;
        }
    }

    &--button {
        margin-top: rem(12);

        @include breakpoint(large) {
            margin-top: 0;
        }

        &.is-loading {
            pointer-events: none;
            &:after {
                content: "";
                display: block;
                width: rem(22);
                height: rem(22);
                margin-left: rem(10);
                background: currentColor;
                border-radius: 50%;
                transform: scale(1);
                animation: heartbeat 2s infinite;
            }
        }
    }
}

.c-songvoting + .c-form {
    margin-top: rem(-16);
}
