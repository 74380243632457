.c-badge {
    position: relative;

    &__number {
        position: absolute;
        top: rem(-5);
        left: 100%;
        transform: translateX(-50%);
        padding: rem(1 6);
        color: cssvar(color-primary);
        background: cssvar(color-secondary);
        border-radius: rem(60);
        font-size: rem(12);
        font-weight: $global-weight-bold;
        font-family: cssvar(header-font-family);
        letter-spacing: cssvar(header-font-spacing);
        line-height: rem(16);
        z-index: 1;

        @include tpl(rockantenne) {
            padding: rem(2 6 0);
            color: cssvar(color-white);
        }
    }

    &--ingrid {
        display: flex;
        margin-inline: rem(8);
        text-align: center;

        .o-trafficsymbol   {
            width: 100%;
            padding: rem(0 5);
        }
    }
}
