html {
    height: 100vh;
    // mobile viewport bug fix: https://allthingssmitty.com/2020/05/11/css-fix-for-100vh-in-mobile-webkit/
    height: -webkit-fill-available;
    scroll-behavior: smooth;
}

body {
    min-height: 100vh;
    // mobile viewport bug fix: https://allthingssmitty.com/2020/05/11/css-fix-for-100vh-in-mobile-webkit/
    min-height: -webkit-fill-available;
    background: cssvar(color-background);

    @include tpl(oldieantenne) {
        background-image: url(/dist/websites/regwall-bg-oldieantenne.svg);
        background-position: center top;
        background-size: rem(680);
        background-repeat: repeat;
    }
}

// CSS View Transitions – see https://developer.chrome.com/docs/web-platform/view-transitions/same-document#view-transition-types
::view-transition-old(tabnav), ::view-transition-old(.form) {
    animation: 90ms cubic-bezier(0.4, 0, 1, 1) both fadeOut,
        300ms cubic-bezier(0.4, 0, 0.2, 1) both move-out-left;
}

::view-transition-new(tabnav), ::view-transition-new(.form) {
    animation: 210ms cubic-bezier(0, 0, 0.2, 1) 90ms both fadeIn,
        300ms cubic-bezier(0.4, 0, 0.2, 1) both move-in-left;
}

.l-page-oauthcontent {
    view-transition-name: page-oauthcontent;
}
::view-transition-old(page-oauthcontent) {
    animation: 900ms cubic-bezier(0.4, 0, 0.2, 1) both slide-out-left;
}

::view-transition-new(page-oauthcontent) {
    animation: 900ms cubic-bezier(0.4, 0, 0.2, 1) both slide-in-right;
}

// this is for fixing the footer to the bottom of the viewport (because of min-height not working in IE)
.l-page-wrapper {
    min-height: 100vh; // TODO: this should again cause the mobile viewport bug I guess!
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    overflow-x: clip;
    // env safe-area-inset (especially needed for our in-app webviews). Falls back to 0
    padding-top: env(safe-area-inset-top, 0px);
    padding-bottom: env(safe-area-inset-bottom, 0px);

    &:before {
        content: '';
        position: fixed;
        top: auto;
        left: 0;
        right: 0;
        bottom: 0;
        height: rem(141);
        z-index: 99; // should be 1 less than .c-player
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.0001) 1.04%, rgba(255, 255, 255, 0.18) 42.19%, rgba(255, 255, 255, 0.8) 100%);
        mix-blend-mode: normal;
        opacity: 0.7;
        pointer-events: none; // prevent any click events here

        @include tpl(rockantenne, oldieantenne) {
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.0001) 1.04%, rgba(0, 0, 0, 0.18) 42.19%, rgba(0, 0, 0, 0.8) 100%);
        }
    }
}

.l-page-content {
    position: relative; // needed for fireplace-ads!
    flex: 1 0 auto; // allow grow page-content, to fix footer to bottom
    --grid-backgroundcolor: #{cssvar(color-background)}; // for .l-container -> needed for fireplace ads!

    .l-container--content:last-child {
        padding-bottom: rem(50); // for website-footer padding
    }
}

.l-page-content, .o-reklame--bb_1 {
    transition: opacity 0.2s ease-in-out;
    html.navhandler-is-loading & {
        opacity: 0.4;
        animation: nprogressPageFade ease-in 4s;
    }
}

.l-page-header,
.l-page-footer {
    width: 100%; // needed for firefox and edge display error
    flex: 0 0 auto;
}

@include tpl(oldieantenne) {
    @include breakpoint(large, down) { // for small+medium only!
        .l-page-footer {
            margin-top: 1rem;
        }
        .c-footer__logo-wrapper {
            margin-top: -0.5rem;
        }
    }
}

.l-container {
    position: relative; // needed for fireplace-ads!
    width: 100%;
    margin: 0 auto;
    padding: 0 cssvar(content-gutter-right) 0 cssvar(content-gutter-left);
    background-color: var(--grid-backgroundcolor, transparent);
    color: var(--grid-color);

    &--content {
        max-width: $global-width;

        // because of our striped background, oldieantenne needs padding instead of margings but only for containers
        @include tpl(oldieantenne) {
            &.u-extra-medium-margin--top {
                margin-top: 0 !important;
                padding-top: 1.5rem !important;
            }
            &.u-extra-large-margin--top {
                margin-top: 0 !important;
                padding-top: 2.25rem !important;
            }
            &.u-extra-xlarge-margin--top {
                margin-top: 0 !important;
                padding-top: 3rem !important;
            }
            &.u-extra-medium-margin--bottom {
                margin-bottom: 0 !important;
                padding-bottom: 1.5rem !important;
            }
            &.u-extra-large-margin--bottom {
                margin-bottom: 0 !important;
                padding-bottom: 2.25rem !important;
            }
            &.u-extra-xlarge-margin--tbottomop {
                margin-bottom: 0 !important;
                padding-bottom: 3rem !important;
            }
        }
    }

    &--full {
        padding-left: 0;
        padding-right: 0;
    }
}

.l-page-header {
    z-index: 1;
    view-transition-name: page-header;
    @include setcssvar(color-foreground, cssvar(color-header-foreground, cssvar(color-white)));
    position: relative;
    height: auto;
    margin: 0;
    padding: rem(18 0 0);
    color: cssvar(color-foreground);
    background: #126fb2;
    overflow-x: hidden;

    @include tpl(rockantenne) {
        background: #173557;
        // The header svgs were showing outside of header which was covering up the content. Social media sharing link was not clickable.
        overflow: hidden;
    }

    @include tpl(oldieantenne) {
        background: cssvar(color-background);
    }

    // Needed for &::after gradient
    >* {
        position: relative;
        z-index: 2;
    }

    // Header background gradient
    &::after {
        z-index: 1;
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: rem(100);
        width: 100%;
        background: linear-gradient(cssvar(color-primary) 0%, rgba(0, 0, 0, 0) 80%);
        opacity: 0.65;

        @include tpl(rockantenne) {
            background: linear-gradient(cssvar(color-background) 0%, rgba(0, 0, 0, 0) 80%);
        }

        @include tpl(oldieantenne) {
            display: none;
        }
    }

    &__background {
        display: block;
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        z-index: 0;

        //header icons for rockantenne
        @include tpl(rockantenne) {
            width: rem(1920);

            @include breakpoint(large) {
                width: 100%;
            }

            ul {
                list-style-type: none;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;

                li {
                    position: absolute;
                    display: block;

                    .icon {
                        width: 125px;
                        height: 125px;
                    }
                }

                li:nth-child(1) {
                    left: 1%;

                    @include breakpoint(medium) {
                        left: 10%;
                    }

                    svg {
                        max-width: rem(30);
                        margin-top: rem(50);
                        transform: rotate(random(22) + deg);

                        @include breakpoint(medium) {
                            max-width: rem(60);
                        }
                    }
                }

                li:nth-child(2) {
                    left: 8%;

                    @include breakpoint(medium) {
                        left: 17%;
                    }

                    svg {
                        max-width: rem(60);
                        margin-top: rem(-50);
                        transform: rotate(random(22) + deg);

                        @include breakpoint(medium) {
                            max-width: rem(30);
                        }
                    }
                }

                li:nth-child(3) {
                    left: 15%;

                    @include breakpoint(medium) {
                        left: 21%;
                    }

                    svg {
                        margin-top: rem(13);
                        max-width: rem(55);
                        transform: rotate(random(22) + deg);


                        @include breakpoint(medium) {
                            margin-top: rem(30);
                            max-width: rem(75);
                        }
                    }
                }

                li:nth-child(4) {
                    left: 28%;

                    svg {
                        margin-top: 10px;
                        max-width: rem(60);
                        transform: rotate(random(22) + deg);
                    }
                }

                li:nth-child(5) {
                    left: 34%;

                    svg {
                        margin-top: -50px;
                        max-width: rem(30);
                        transform: rotate(random(22) + deg);
                    }
                }

                li:nth-child(6) {
                    left: 37%;

                    svg {
                        margin-top: 20px;
                        max-width: rem(70);
                        transform: rotate(random(22) + deg);
                    }
                }

                li:nth-child(7) {
                    left: 42%;

                    svg {
                        margin-top: -40px;
                        max-width: rem(40);
                        transform: rotate(random(22) + deg);
                    }
                }

                li:nth-child(8) {
                    left: 47%;

                    svg {
                        margin-top: -23px;
                        max-width: rem(120);
                        transform: rotate(random(22) + deg);
                    }
                }

                li:nth-child(9) {
                    left: 54%;

                    svg {
                        margin-top: 70px;
                        max-width: rem(30);
                        transform: rotate(random(22) + deg);
                    }
                }

                li:nth-child(10) {
                    left: 59%;

                    svg {
                        margin-top: -25px;
                        max-width: rem(40);
                        transform: rotate(random(22) + deg);
                    }
                }

                li:nth-child(11) {
                    left: 61%;

                    svg {
                        margin-top: 60px;
                        max-width: rem(70);
                        transform: rotate(random(22) + deg);
                    }
                }

                li:nth-child(12) {
                    left: 69%;

                    svg {
                        margin-top: -30px;
                        max-width: rem(90);
                        transform: rotate(random(22) + deg);
                    }
                }

                li:nth-child(13) {
                    left: 77%;

                    svg {
                        margin-top: 75px;
                        max-width: rem(55);
                        transform: rotate(random(22) + deg);
                    }
                }

                li:nth-child(14) {
                    left: 85%;

                    svg {
                        margin-top: -5px;
                        max-width: rem(80);
                        transform: rotate(random(22) + deg);
                    }
                }
            }
        }

        @include tpl(antenne) {

            // on small viewport, only right side is moved to be in the viewport
            [data-links-dunkel],
            [data-links-hell] {
                transform: none;
            }

            [data-rechts-dunkel] {
                transform: translate(calc(-400px + 50vw), -50px);
            }

            [data-rechts-hell] {
                transform: translate(calc(-345px + 50vw), -90px);
            }

            // on medium+large viewport, all nodes with transformlimits are transformed
            [data-transformlimits] {
                @include breakpoint(medium) {
                    transform: translate(var(--translate, "0, 0"));
                    transition: transform ease-in-out 1.5s;
                }
            }
        }

        @include tpl(oldieantenne) {
            display: none;
        }
    }

    @include tpl(antenne) {
        &__background {
            display: none;
        }
        &__mobilebackground, &__fallbackbackground {
            display: block;
            position: absolute;
            bottom: 0;
            height: 100%;
            width: 100%;
            z-index: 0;
            object-fit: fill;
            object-position: bottom right;
        }
        &__fallbackbackground {
            display: none;
            left: 50%;
            transform: translateX(-50%);
            max-width: rem(1680);
            object-fit: fill;
            object-position: bottom center;
        }
        @include breakpoint(medium) {
            &__background {
                display: block;
            }
            &__mobilebackground {
                display: none;
            }

            &.is-fallback-background {
                background-image: url("/dist/websites/page-header-fallbackrepeatbackground-antenne.jpg");
                background-repeat: repeat-x;
                background-position: bottom center;
                background-size: rem(1 215);
                .l-page-header__background {
                    display: none;
                }
                .l-page-header__fallbackbackground {
                    display: block;
                }
            }
        }

    }
}

.has-hero {
    .l-page-header {
        background: transparent !important;
        height: rem(144.41);

        @include breakpoint(medium) {
            min-height: rem(214.81);
        }

        &__background, &__fallbackbackground, &__mobilebackground {
            display: none !important;
        }

        pointer-events: none;
        .c-headeractions {
            pointer-events: all;
        }
    }
    [data-navhandlersection="reklame-bb"] {
        height: rem(126);
        @include breakpoint(rem(1024)) {
            height: rem(276);
        }
    }
}

#nprogress {
    position: relative;
    z-index: 99999;
    .bar {
        background-color: cssvar(color-secondary);
        height: rem(4);
    }

    .peg {
        display: none;
    }
}

@keyframes nprogressPageFade {

    0%,
    30% {
        opacity: 1;
    }

    90% {
        opacity: 0.45;
    }

    100% {
        opacity: 0.4;
    }
}

.l-traffic-list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: grid;
    grid-auto-rows: rem(30);
    grid-template-columns: repeat(3, auto);
    gap: 5px;
    max-width: rem(190);
}
