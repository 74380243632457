.c-countdown {
    min-height: 4.3em;
    margin: rem(10) 0 $global-margin;
}

.flip-clock {
    text-align: center;
    perspective: 400px;

    .flip-clock__piece {
        display: inline-block;
        margin: 0 5px;
    }

    .flip-clock__slot {
        font-size: 0.75em;
        text-transform: uppercase;
        font-weight: 700;
    }

    $halfHeight: 0.72em;

    .card {
        display: block;
        position: relative;
        padding-bottom: $halfHeight;
        font-size: 2em;
        line-height: 0.95;
    }

    .card__top,
    .card__bottom,
    .card__back::before,
    .card__back::after {
        display: block;
        height: $halfHeight;
        color: #ccc;
        background: #014999;
        padding: 0.25em 0.25em;
        border-radius: $global-radius $global-radius 0 0;
        backface-visiblity: hidden;
        transform-style: preserve-3d;
        width: 1.81em;
        transform: translateZ(0);
        line-height: 1;
    }

    .card__bottom {
        color: #FFF;
        position: absolute;
        top: 50%;
        left: 0;
        border-top: solid 1px #023670;
        background: #0d59a7;
        border-radius: 0 0 $global-radius $global-radius;
        pointer-events: none;
        overflow: hidden;
    }

    .card__bottom::after {
        display: block;
        margin-top: -$halfHeight;
    }

    .card__back::before,
    .card__bottom::after {
        content: attr(data-value);
    }

    .card__back {
        position: absolute;
        top: 0;
        height: 100%;
        left: 0%;
        pointer-events: none;
    }

    .card__back::before {
        position: relative;
        z-index: -1;
        overflow: hidden;
    }

    .flip .card__back::before {
        animation: flipTop 0.3s cubic-bezier(.37, .01, .94, .35);
        animation-fill-mode: both;
        transform-origin: center bottom;
    }

    .flip .card__back .card__bottom {
        transform-origin: center top;
        animation-fill-mode: both;
        animation: flipBottom 0.6s cubic-bezier(.15, .45, .28, 1); // 0.3s;
    }
}

@keyframes flipTop {
    0% {
        transform: rotateX(0deg);
        z-index: 2;
    }

    0%,
    99% {
        opacity: 0.99;
    }

    100% {
        transform: rotateX(-90deg);
        opacity: 0;
    }
}

@keyframes flipBottom {

    0%,
    50% {
        z-index: -1;
        transform: rotateX(90deg);
        opacity: 0;
    }

    51% {
        opacity: 0.99;
    }

    100% {
        opacity: 0.99;
        transform: rotateX(0deg);
        z-index: 5;
    }
}
