.c-tabs {
    [role="tablist"] {
        display: none;
        margin: rem(0 0 -2 0);
        padding: 0;
        gap: rem(8);
        list-style: none;

        @include tpl(rockantenne) {
            font-family: cssvar(header-font-family);
            letter-spacing: cssvar(header-font-spacing);
        }
    }

    [role="tab"] {
        position: relative;
        text-align: center;
        cursor: pointer;
        padding: rem(14 16);
        flex-grow: 1;
        box-shadow: $box-shadow;

        background: rgba(0, 71, 151, 0.8);
        background: color-mix(in srgb, cssvar(color-primary) 70%, transparent);

        color: cssvar(color-white);
        font-weight: $global-weight-bold;
        text-transform: uppercase;
        font-size: rem(18);
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        backdrop-filter: blur(rem(4));
        transition: background-color ease-in-out 0.3s;
        border-radius: cssvar(border-radius) cssvar(border-radius) 0 0;

        @include tpl(rockantenne) {
            color: cssvar(color-white);
            background-color: cssvar(color-secondary);

            &:first-child {
                margin-right: rem(-6);
                clip-path: polygon(0 0, 100% 0%, 96% 100%, 0% 100%);

                @include breakpoint(medium) {
                    margin-right: rem(-15);
                }
            }

            &:nth-child(2) {
                clip-path: polygon(4% 0%, 100% 0%, 100% 100%, 0% 100%);
            }
        }

        @include tpl(oldieantenne) {
            background: cssvar(color-primary);
            color: cssvar(color-background);
        }
    }

    [role="tab"]:hover {
        // TODO: include color-primary
        background: rgba(0, 71, 151, 0.99);
    }

    [role="tab"][aria-selected="true"] {
        background: cssvar(color-background);
        color: cssvar(color-primary);

        @include tpl(rockantenne) {
            margin-bottom: rem(-1);
            background-color: cssvar(color-mediumblue);
        }

        @include tpl(oldieantenne) {
            background: cssvar(color-background);
            color: cssvar(color-white);
        }
    }

    &__tabpanels {
        padding: 1px cssvar(content-gutter-right) 1px cssvar(content-gutter-left);
        position: relative;
        background: cssvar(color-background);
    }

    &__list {
        &--2col {
            .c-tabs__listitem {
                @include breakpoint(medium) {
                    width: 50%;
                }
            }
        }
    }

    @at-root html.u-supports-js {
        [role="tablist"] {
            display: flex;
        }

        .c-tabs__tabpanels [role="tabpanel"] {
            display: none;
        }

        .c-tabs__tabpanels [role="tabpanel"][aria-expanded="true"] {
            display: block;
        }
    }
}

//variant with boxshadow
.c-tabs--shadow {
    .c-tabs__tabpanels {
        padding-bottom: rem(15);
        border-radius: 0 0 cssvar(border-radius) cssvar(border-radius);
        box-shadow: 0px 4px 6px -1px rgba(15, 23, 42, 0.1);
    }
}

//variant with gradient background
.c-tabs--gradient {
    .c-tabs__tabpanels {
        @include tpl(rockantenne) {
            background: linear-gradient(180deg, cssvar(color-mediumblue) 0%, var(--grid-backgroundcolor, transparent) 400px);

            .c-tabs__tabcontent > .l-container {
                background: transparent;
            }
        }
    }
}

//webcams
.c-tabs--webcams {
    .o-pietimer {
        position: absolute;
        right: 1.625rem;
        top: 1.625rem;
    }
}
