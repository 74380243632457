/* ==========================================================================
   Dialog
   - Implementation of this dialog tool: https: //a11y-dialog.netlify.app/
   - Regwall styles
   - Xtra Regwall styles
   ========================================================================== */

.c-dialog {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 101;

    &[aria-hidden="true"] {
        display: none;
    }

    &__overlay {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba(0, 0, 0,  0.5);
        animation: fade-in-overlay 400ms both;
    }

    &__content {
        z-index: 2;
        position: relative;
        animation: fade-in-slide-up 400ms 200ms both;
        margin: auto;
        overflow-x: hidden;
        overflow-y: auto; // needed to  hide image behind rounded corners
        max-height: 95vh;

        // hide scrollbar
        scrollbar-width: none; // Firefox

        &::-webkit-scrollbar {
            display: none; // Safari and Chrome
        }

        &:not(.c-dialog__content--whitebg) {
            background-color: cssvar(color-primary-85);
            color: cssvar(color-white);

            @include tpl(antenne) {
                background-color: cssvar(color-primary-80);
                border-radius: $global-radius;
            }
        }

        @include tpl(oldieantenne) {
            box-shadow: 0 0 100px -30px cssvar(color-secondary);
        }

        &--whitebg {
            background-color: cssvar(color-background);
            color: cssvar(color-foreground);
        }

        &--errordialog {
            padding: 1.5rem;
            h1 {
                margin-top: 1rem;
            }
        }

        .c-embed {
            margin: 0;
        }

        // Sizing
        width: rem(375);
        max-width: 100%;

        [data-backlink] {
            display: none;
        }
    }

    &__close {
        position: absolute;
        z-index: 4;
        top: 0;
        right: rem(8);
        border: 0;
        padding: rem(12);
        background-color: transparent;
        text-align: center;
        cursor: pointer;
        transition: 0.15s;

        svg {
            width: rem(14);
            height: rem(14);
        }

        &:hover {
            svg {
                fill: cssvar(color-secondary);
            }
        }
    }

    // Variations
    &--large .c-dialog__content {

        @include breakpoint (large) {
            height: auto;
            width: map-get($breakpoints, large);
        }
    }

    &--show-overflow {
        .c-dialog__content {
            overflow: visible;
        }
    }

    &--companion-ad {
        .c-dialog__content {
            background-color: transparent;
            width: auto;
        }

        .c-dialog__close {
            right: rem(-40);
        }
    }

    /*
    * Geolocation Dialog
    */
    &--geolocation {
        svg {
            fill: cssvar(color-primary);
        }
    }

    &__geolocation-content {
        padding: $global-margin*3 $global-margin*2 $global-margin;
        background-color: cssvar(color-white);
        color: cssvar(color-black);

        .c-button {
            margin: auto;
        }
    }
}
