.c-quiz {
    position: relative;
    min-height: calc(var(--grid-cell-width) * 1.4637681159);
    margin-bottom: $global-margin;
    overflow: hidden;
    box-shadow: $box-shadow;
    background-color: cssvar(color-foreground);
    transition: box-shadow ease-in-out 0.4s, background-color ease-in-out 0.4s;

    @include tpl(antenne) {
        border-radius: $global-radius;
    }

    @include setcssvar(
        color-foreground,
        cssvar(color-header-foreground, cssvar(color-white))
    );

    @include tpl(rockantenne) {
        @include setcssvar(color-foreground, cssvar(color-mediumblue));
        color: cssvar(color-white);
    }

    @include tpl(oldieantenne) {
        @include setcssvar(color-foreground, cssvar(color-darkgray));
        color: cssvar(color-white);
    }

    @include breakpoint(medium) {
        min-height: calc(var(--grid-cell-width) * 0.7421875);
    }

    &--schlaubayeroftheday {
        background: transparent;
        box-shadow: none;
        min-height: 0;
    }

    &:has(.c-quiz__slide--promotionform.is-active) {
        box-shadow: none;
        overflow: visible;
        background: rgba(255,255,255,0);
    }

    &__slide {
        min-height: inherit;
        display:flex;
        flex-direction: column;
        justify-content: center;
        padding: rem(35) $global-margin * 2 rem(25);

        @include breakpoint (medium) {
            padding: rem(55) $global-margin * 4 rem(25);
        }

        // visually hidden
        position: absolute;
        width: 1px;
        height: 1px;
        overflow: hidden;
        clip: rect(1px, 1px, 1px, 1px);

        &.is-active {
            // visually hidden reset
            position: relative;
            top: 0;
            width: 100%;
            height: unset;
            overflow: unset;
            clip: unset;
        }

        &.is-active:not(.c-quiz__slide--cover) {
            animation: slide-in-right 0.50s cubic-bezier(0.445, 0.050, 0.550, 0.950);
        }

        // `:not(.trolling-css)` needed here to increase specificity and overrule `&.is-active:not(.c-quiz__slide--cover)`
        &.u-slide-out:not(.trolling-css) {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            animation: slide-out-left 0.30s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
        }
        &.u-fade-out:not(.trolling-css) {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            animation: fadeOut 0.30s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
        }

        &--promotionform {
            padding: 0;
        }
    }

    &__slide-content {
        text-align: center;
    }

    &__slide--cover {
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: inherit;
        cursor: pointer;
        text-align: center;

        @include breakpoint(medium) {
            height: calc(var(--grid-cell-width) * 0.7421875) !important;
        }

        .c-image {
            position: absolute;
            inset: 0;
            min-height: inherit;


            @include breakpoint(medium) {
                height: calc(var(--grid-cell-width) * 0.7421875) !important;
            }


            picture {
                height: inherit;
                min-height: inherit;
            }

            img {
                height: inherit;
                min-height: inherit;
                object-fit: cover;
            }
        }
    }

    &__button-wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
            margin-bottom: rem(28);
        }
    }

    &__button-next {
        margin:0 auto;
        transition: opacity 0.4s ease-in-out;

        @include breakpoint(medium) {
            margin-top: rem(-16);
            margin-right: 0;
        }
    }

    &__button-next.u-disabled {
        opacity: 0;
    }

    &__button-reload  {
        margin: 0 auto rem(50);
    }

    &__button-promotion  {
        width: fit-content;
        margin: 0 auto rem(20);
    }

    // circle countdown
    &__countdown {
        position: relative;
        display: flex;
        flex-direction: column;
        margin: 0 auto $global-margin;
        height: rem(60);
        width: rem(60);
        justify-content: center;
        align-items: center;

        transform: scale(0.9);

        @include breakpoint(medium) {
            transform: scale(1);
        }

        svg {
            position: absolute;
            top: 0;
            right: 0;
            width: rem(60);
            height:rem(60);
            transform: rotateY(-180deg) rotateZ(-90deg);
        }

        svg circle {
            stroke-dasharray: 156;
            stroke-dashoffset: 0;
            stroke-width: 5px;
            stroke: currentColor;
            fill: none;
            animation: countdown var(--animation-duration) linear forwards;
            transition: stroke 1s linear;
        }
    }
}
