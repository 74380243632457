.c-tabnav {
    margin-bottom: rem(22);
     /*
    * Forces the tab nav to jump out of the grid on the right side, showing
    * mobile users that there is more content to scroll to.
    * When scrolling the tabs jump out of the grid on the left side.
    */
    margin-right: calc(cssvar(grid-gutter)/-2 - cssvar(content-gutter-right));
    margin-left: calc(cssvar(grid-gutter)/-2 - cssvar(content-gutter-left));

    @include breakpoint(large) {
        margin-right: 0;
        margin-left: 0;
    }

    &__track {
        position: relative;

        @include tpl(rockantenne) {
            transform: skewX(-12deg);
        }

        @include tpl(oldieantenne) {
            transform: skewX(-45deg);
            margin-left: rem(-22);
            width: calc(100% + #{rem(22)});
        }
    }

    &__list {
        display: flex;
        height: auto;
        margin: 0 0 0 calc(var(--antenne-grid-gutter, )/2);
        padding: 0;
        list-style: none;

        // scrolling
        width: auto;
        flex-wrap: nowrap;
        overflow-x: auto;
        scroll-behavior: smooth;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }

        @include breakpoint(large) {
            margin-left: 0;
        }
    }

    &__item {
        display: flex;
        background-color: cssvar(color-primary);
        cursor: pointer;
        flex: 0 0 auto;
        font-family: cssvar(header-font-family);
        letter-spacing: cssvar(header-font-spacing);
        transition: background .3s ease-in-out;
        scroll-snap-align: start;

        @include tpl(antenne) {
            border-radius: rem(6 0 6 0);
        }

        @include tpl(rockantenne) {
            background-color: cssvar(color-white);
        }

        &:last-child {
            margin-right: cssvar(content-gutter-right);
        }

        &:first-child {
            margin-left: cssvar(content-gutter-left);
        }

        @include breakpoint(large) {
            &:last-child {
                margin-right: 0;
            }

            &:first-child {
                margin-left: 0;
            }

            @include tpl(rockantenne, oldieantenne) {
                &:first-child {
                    // Needed because of the skewed shape of the link
                    margin-left: rem(5);
                }
            }
        }

        input,
        label {
            cursor: pointer;
        }

        a,
        p,
        label {
            padding: rem(10 15);
            font-weight: bold;
            text-decoration: none;
            color: cssvar(color-white);
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            transition: none;
            background: transparent !important; // Override eventual background changes from resource link_attributes

            @include tpl(rockantenne) {
                display: block;
                padding: rem(10 15 7);
                transform: skewX(12deg);
                color: cssvar(color-darkblue);
            }

            @include tpl(oldieantenne) {
                display: block;
                padding: rem(7 26);
                color: cssvar(color-background);
            }
        }

        @include tpl(oldieantenne) {
            a, p {
                transform: skewX(45deg);
            }
        }

        &.is-active,
        &[aria-selected="true"] {
            background-color: cssvar(color-secondary) !important;

            a,
            p {
                color: cssvar(color-primary) !important;

                @include tpl(rockantenne) {
                    color: cssvar(color-white) !important;
                }
            }
        }

        // Only want hover effect on devices with mouse. These property settings are
        // identical to above.
        @media (pointer: fine) {
            &:hover {
                background-color: cssvar(color-secondary) !important;

                a,
                p {
                    color: cssvar(color-primary) !important;

                    @include tpl(rockantenne) {
                        color: cssvar(color-white) !important;
                    }
                }
            }
        }

        &:not(:last-child) {
            margin-right: rem(8)
        }
    }

    &__controls {
        position: absolute;
        top: 0;
        display: none;
        padding: rem(10);
        margin: 0;
        background: none;
        border: none;
        cursor: pointer;
        transition: opacity .3s ease-in-out;

        @media (hover: none) {
            display: none !important;
        }

        @include tpl(rockantenne) {
            transform: skewX(12deg);
        }

        &.is-disabled {
            cursor: none;
            visibility: hidden;
            opacity: 0;
        }

        svg {
            width: rem(10);
            height: rem(10);
            fill: cssvar(color-white);
        }

        &--prev {
            left: rem(-30);
        }

        &--next {
            right: rem(-30);
        }
    }

    &--has-arrows {

        margin-inline: 0;

        .c-tabnav__list {
            margin: 0;
        }

        .c-tabnav__item {
            &:first-child {
                margin-left: 0;

                @include tpl(rockantenne) {
                    margin-left: rem(5);
                }

            }
        }

        .c-tabnav__controls {
            display: block;
        }

    }
}

// radiobuttons variant
.c-tabnav--radiobuttons {
    .c-tabnav__item {
        position: relative;

        @include tpl(antenne) {
            background: transparent !important;
        }

        input[type="radio"] {
            position: absolute;
            inset: 0;
            opacity: 0;
            z-index: 1;
        }

        &:hover {
            label {
                @include tpl(antenne) {
                    background-color: cssvar(color-secondary) !important;
                    color: cssvar(color-primary);
                    transition: background-color .3s ease-in-out;
                }
            }
        }

        input[type="radio"]:checked + label {
            background-color: cssvar(color-secondary) !important;

            p {
                color: cssvar(color-primary) !important;

                @include tpl(rockantenne) {
                    color: cssvar(color-white) !important;
                }
            }
        }

        label {
            @include tpl(antenne) {
                background: cssvar(color-primary) !important;
                border-radius: rem(6 0 6 0);
            }

            @include tpl(rockantenne) {
                transform: none;

                p {
                    transform: skewX(12deg);
                }
            }
        }
    }
}


/*
* Use case where tab nav is in content area with white background.
* This case exists on the antenne template.
*/

main {
    @include tpl(antenne) {
        .c-tabnav__controls {
            svg {
                fill: cssvar(color-primary);
            }
        }
    }
}
