@keyframes marquee {
    from {
        transform: translateX(100vw);
    }

    to {
        transform: translateX(-100%);
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes heartbeat {
    0% {
        transform: scale(.75);
    }

    20% {
        transform: scale(1);
    }

    40% {
        transform: scale(.75);
    }

    60% {
        transform: scale(1);
    }

    80% {
        transform: scale(.75);
    }

    100% {
        transform: scale(.75);
    }
}

@keyframes move-out-left {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-3rem);
    }
}

@keyframes move-in-left {
    0% {
        transform: translateX(3rem);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes fade-in-move-left {
    0% {
        opacity: 0;
        transform: translateX(3rem);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fade-in-move-up {
    0% {
        opacity: 0;
        transform: translateY(3rem);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fade-in-slide-up {
    0% {
        opacity: 0;
        transform: translateY(10%);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fade-in-overlay {
    from {
        opacity: 0;
        backdrop-filter: blur(0);
    }

    to {
        opacity: 1;
        backdrop-filter: blur(8px);
    }
}

@keyframes pulsate {
    from {
        opacity: 1;

    }

    50% {
        opacity: 0.3;
    }

    to {
        opacity: 1;
    }
}

@keyframes wobbleScale {

    0%,
    100% {
        transform: scale(1);
        transform-origin: 50% 50%;
    }

    15% {
        transform: scale(0.9);
    }

    30% {
        transform: scale(1.1);
    }

    45% {
        transform: scale(0.95);
    }

    60% {
        transform: scale(1.04);
    }

    75% {
        transform: scale(0.98);
    }
}

@keyframes wobbleRotate {

    0%,
    100% {
        transform: translateX(0%);
        transform-origin: 50% 50%;
    }

    15% {
        transform: translateX(-0px) rotate(-10deg);
    }

    30% {
        transform: translateX(calc(0px / 2)) rotate(10deg);
    }

    45% {
        transform: translateX(calc(-0px / 2)) rotate(calc(-10deg / 1.8));
    }

    60% {
        transform: translateX(calc(0px / 3.3)) rotate(calc(10deg / 3));
    }

    75% {
        transform: translateX(calc(-0px / 5.5)) rotate(calc(-10deg / 5));
    }
}

@keyframes shake-horizontal {
    0%,
    100% {
        transform: translateX(0);
    }
    10%,
    30%,
    50%,
    70% {
        transform: translateX(-10px);
    }
    20%,
    40%,
    60% {
        transform: translateX(10px);
    }
    80% {
        transform: translateX(8px);
    }
    90% {
        transform: translateX(-8px);
    }
}

@keyframes jello-horizontal {
    0% {
      transform: scale3d(1, 1, 1);
    }
    30% {
      transform: scale3d(1.25, 0.75, 1);
    }
    40% {
      transform: scale3d(0.75, 1.25, 1);
    }
    50% {
      transform: scale3d(1.15, 0.85, 1);
    }
    65% {
      transform: scale3d(0.95, 1.05, 1);
    }
    75% {
      transform: scale3d(1.05, 0.95, 1);
    }
    100% {
      transform: scale3d(1, 1, 1);
    }
}

@keyframes countdown {
    from {
      stroke-dashoffset: 0;
    }
    to {
      stroke-dashoffset: 156;
    }
}

@keyframes slide-in-right {
    0% {
        transform: translateX(100vw);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slide-out-left {
    0% {
        transform: translateX(0);
        opacity: 1;
    }
    100% {
        transform: translateX(-100vw);
        opacity: 0;
    }
}

@keyframes slide-out-bottom {
    0% {
        transform: translateY(0);
        opacity: 1;
    }
    100% {
        transform: translateY(100vh);
        opacity: 0;
    }
}

@keyframes bounce {
    0%   { transform: translateY(0); }
    60%  { transform: translateY(-20px); }
    100% { transform: translateY(0); }
}
