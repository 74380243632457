/* ==========================================================================
   Hero Component
   - Calculates dynamically margin-bottom to force content to overlap hero
   ========================================================================== */

.c-hero {
    z-index: 0;
    position: relative;
    display: block;
    width: 100%;
    height: calc(100vw * cssvar(image-ratio));
    background: cssvar(color-background);
    color: cssvar(color-background);

    &::after {
        content: "";
        z-index: -1;
        display: block;
        background: var(--hero-background, cssvar(color-background));
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0.8;
    }

    //margin-top: calc(cssvar(header-height) * -1 - 10px - var(--reklame-bb1-height, 0)); // l-page-header height + padding of `reklame-bb`
    margin-top: rem(-270);
    @include breakpoint(medium) {
        margin-top: rem(-341);
    }
    @include breakpoint(rem(1024)) {
        margin-top: rem(-491);
    }


    @include setcssvars(('image-ratio': 1,
            'image-content-overlap': .37));

    margin-bottom: calc(100vw * cssvar(image-ratio) * cssvar(image-content-overlap) * -1);

    @include breakpoint(large) {
        @include setcssvars(('image-ratio': 0.5625, // 9/16 calculation to get 16 to 9 ratio
                'image-content-overlap': .3 // percentage - how far it should move the content up
            ));
    }

    @include tpl(rockantenne) {
        @include setcssvars(('image-ratio': 1.509333,
                'image-content-overlap': .4));

        @include breakpoint(large) {
            @include setcssvars(('image-ratio': 0.6847222));
        }
    }

    @include tpl(oldieantenne) {
        @include breakpoint(large) {
            @include setcssvars(('image-ratio': 0.3, // 9/16 calculation to get 16 to 9 ratio
                    'image-content-overlap': -0.025 // percentage - how far it should move the content up
                ));
        }
    }

    picture,
    img {
        height: calc(100vw * cssvar(image-ratio));
        width: 100%;
        object-fit: cover;
    }
}

// show only 1 single hero, if 2 or more heros are rendered by accident
.c-hero + .c-hero {
    display: none;
}
