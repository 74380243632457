.c-tokenhunt-token {
    z-index: 99;
    position: fixed;
    bottom: rem(74);

    html.tempalte--inlinewebview & {
        display: none !important;
    }

    html.tempalte--webview & {
        bottom: 0;
    }

    .template--nativeapp:not(.template--nativeapp-v5) & {
        bottom: cssvar(safe-area-bottom);
    }

    --width: #{rem(130)};
    @include breakpoint (medium) {
        --width: #{rem(150)};
    }

    left: max(1rem, calc(var(--dynmaicLeft, 0px) - var(--width) - var(--addedWidth, 0px)));

    &--invitation {
        --addedWidth: #{rem(250)};
        @include breakpoint (medium) {
            --addedWidth: #{rem(265)};
        }
    }

    opacity: 0;
    animation: 0.4s ease-in-out 1s 1 fadeIn;
    animation-fill-mode: forwards;

    img {
        width: 100%;
        height: auto;
    }

    &__button {
        display: block;
        width: var(--width);
        /* duration | easing-function | delay | iteration-count | direction | fill-mode | play-state | name */
        animation: 1.5s cubic-bezier(0.32, 0, 0.16, 0.58) 1.2s 4 wobbleScale;
        animation-fill-mode: forwards;
        transform-origin: center bottom;
    }

    &__invitation {
        position: relative;
        display: block;
        background: cssvar(color-primary);
        color: #fff;
        width: var(--addedWidth);
        position: absolute;
        bottom: rem(16);
        left: calc(var(--width) - #{rem(50)});
        padding: rem(16 16 16 40);
        border-radius: $global-radius;
        box-shadow: $box-shadow;

        .o-headline, p {
            position: relative;
            z-index: 1;
            margin: 0;
        }
    }

    &__close {
        position: absolute;
        top: rem(-12);
        right: rem(-12);
        padding: rem(8);
        background: inherit;
        border-radius: 100%;
        line-height: 1;
    }

    &__closeicon {
        display: block;
        width: rem(14);
        height: rem(14);
    }

    &.slide-out {
        animation: slide-out-bottom 0.5s ease-in; // IMPORTANT! If you change this, adjust the name in tokenhunt.js too!
        animation-fill-mode: forwards;
    }
}

.c-tokenhunt-account {
    &__image_counter {
        position: relative;
        display: inline-block;
        .c-image, .c-image picture img{
            width: rem(100);
            height: auto;
            background-color: transparent;
            @include breakpoint (medium) {
                width: rem(304);
            }
            &.shadow {
            }
        }
        .c-tokenhunt-account__count {
            position: absolute;
            top: 58%;
            left: 85%;
            transform: skew(-8deg,-25deg);
            color: #fff;
            font-size: 2rem;
        }
    }



}
