.c-archive {
    $spacing: rem(8);
    margin: 0 ($spacing * -1) $global-margin;
    padding: 0;
    list-style: none;
    display: grid;
    grid-column-gap: 1.25rem;

    &--years {
        grid-template-columns: repeat(4,1fr);
        @include breakpoint(medium) {
            grid-template-columns: repeat(7,1fr);
        }
        @include breakpoint(large) {
            grid-template-columns: repeat(10,1fr);
        }
    }

    &--months {
        @include breakpoint(medium) {
            grid-template-columns: repeat(2,1fr);
        }
    }

    &--days {
        grid-template-columns: repeat(5,1fr);
        @include breakpoint(medium) {
            grid-template-columns: repeat(8,1fr);
        }
        @include breakpoint(large) {
            grid-template-columns: repeat(16,1fr);
        }
    }

    a {
        display: block;
        padding: rem(16) $spacing;
        text-decoration: none;
        font-size: rem(18);
        border-bottom: rem(1) solid cssvar(color-lightgray);

        @include tpl(rockantenne) {
            border-color: cssvar(color-mediumblue);
            border-width: rem(2);
        }
    }
}
