.c-breadcrumb {
    // 2022-10-25: ABY/Carina wants to hide breadcrumb on small screns for more content space
    display: none;
    position: relative;
    margin: 0 0 rem(12);

    @include breakpoint(medium) {
        display: block;
    }

    &__list {
        display: block;
        list-style: none;
        margin: 0;
        padding: 0;

        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    &__item {
        position: relative;
        display: inline-block;
        margin: 0;
        padding: rem(0 20 0 0);

        &:last-child {
            font-weight: $global-weight-bold;
            padding-right: 0;
        }
    }

    &__link {
        font-size: rem(13);
        color: cssvar(color-white);
        text-decoration: none;

        @include breakpoint(medium) {
            font-size: rem(16);
        }

        // CS 2022-03-23:
        // Attention: "fixing" font smoothing seems to be a bad practice,
        // but was done here (for now) anyway to reflect better the screendesigns
        // read https://usabilitypost.com/2012/11/05/stop-fixing-font-smoothing/
        // and consider not doing this?
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    &__item+&__item {
        &::before,
        &::after {
            content: "";
            position: absolute;
            top: 50%;
            left: rem(-14);
            display: block;
            height: rem(2);
            width: rem(7);
            border-radius: rem(2);
            background: currentColor;
        }

        &::before {
            transform: rotate(46deg) translateX(rem(-3));
        }

        &::after {
            transform: rotate(134deg) translateX(rem(3));
        }
    }
}
