.c-heading {
    align-items: baseline;
    justify-content: space-between;

    .c-link {
        span {
            display: none;

            @include breakpoint(medium) {
                display: inline;
            }
        }
    }

    &--align-right {
        justify-content: flex-end;
    }

    @include tpl(rockantenne) {

        /*
        * Brushes are used on the subheading component and can possibly have a link next to it on the right
        * This link needs to be white, so that it is visible. Targeting this sibling to the data brush element.
        */
        .brush-bg {
            display: table;
            position: relative;
        }

        .brush-bg span {
            position: relative;
        }

        .brush-bg::before {
            content: '';
            background: url(/brush-background.png);
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-position: center 1px;
            position: absolute;
            top: -0.2em;
            bottom: -0.2em;
            left: -1.5em;
            right: -1.5em;
        }
    }

    &.u-text-center .u-separator {
        // move separator to center if heading is centered
        margin-inline: auto;
    }
}
