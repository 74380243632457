$header-item-spacing: rem(10) !default;
@include setrootcssvars((header-height: rem(80),
    ));

@include breakpoint(medium) {
    @include setrootcssvars((header-height: rem(116),
        ));
}

.c-mainlogo {
    display: block;
    width: auto;
    height: rem(46);
    margin: 0 0 $global-margin;

    @include breakpoint(medium) {
        height: rem(82);
    }
}

.c-headeractions {
    display: flex;
    justify-content: flex-start;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    align-items: flex-start;
    height: rem(50);
    overflow: hidden;

    @for $idx from 1 through 10 {
        >*:nth-child(#{$idx}) {
            order: 10-$idx;
        }
    }

    >* {
        flex: 0 0 auto;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    a,
    button {
        position: relative;
        display: block;
        margin: 0 0 rem(50);
        padding: rem(6) $header-item-spacing;
        line-height: 1;
        background: transparent;
        box-shadow: none;
        border: none;
        text-decoration: none;
        color: cssvar(color-foreground);
        fill: cssvar(color-foreground);
        cursor: pointer;
        font-weight: $global-weight-bold;
        font-size: rem(12);

        // CS 2022-03-23:
        // Attention: "fixing" font smoothing seems to be a bad practice,
        // but was done here (for now) anyway to reflect better the screendesigns
        // read https://usabilitypost.com/2012/11/05/stop-fixing-font-smoothing/
        // and consider not doing this?
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        @include breakpoint(medium) {
            font-size: rem(16);
        }

        @include tpl(antenne) {
            &::after {
                content: "";
                display: block;
                position: absolute;
                bottom: rem(-1);
                height: rem(5);
                left: $header-item-spacing;
                right: calc(100% - $header-item-spacing);
                background-color: cssvar(color-secondary);
                transition: right ease-in-out 0.4s;
            }

            &:hover::after,
            &.active::after {
                right: $header-item-spacing;
            }
        }

        @include tpl(rockantenne) {
            text-transform: uppercase;
        }
    }

    .c-headeractions__menu {
        display: flex;
        align-items: center;
        margin-top: rem(-2);

        @include breakpoint(medium) {
            margin-top: rem(-3);
        }
    }

    &__icon {
        width: rem(18);
        height: rem(18);
        fill: inherit;
    }

    .c-headeractions__search-wrapper {
        width: 0px;
        margin-right: rem(-30);
        margin-left: rem(30);
        overflow: hidden;
        transition: width ease-in-out 0.4s, margin ease-in-out 0.4s;
        position: relative;
        top: rem(3);

        &.is-expanded {
            width: rem(175);
            margin-left: $header-item-spacing;

            .c-headeractions__search-input::placeholder {
                opacity: 0.75;
            }
        }

        &::after {
            display: none;
        }
    }

    .c-headeractions__search-input {
        appearance: none;
        display: block;
        width: 100%;
        background: transparent;
        color: cssvar(color-foreground);
        box-shadow: none;
        border: none;
        border-bottom: rem(5) solid cssvar(color-secondary);
        border-radius: 0;
        padding: rem(0 30 0 0);
        line-height: 1;
        height: rem(26);

        font-size: rem(16);
        font-family: inherit;

        &::placeholder {
            color: cssvar(color-foreground);
            opacity: 0;
            transition: opacity ease-in-out 0.3s;
            font-size: inherit;
            font-family: inherit;
        }

        &:focus {
            outline: none;
        }

        &::-webkit-search-decoration,
        &::-webkit-search-cancel-button,
        &::-webkit-search-results-button,
        &::-webkit-search-results-decoration {
            display: none;
        }
    }

    .c-headeractions__search-button {
        z-index: 2;
        position: relative;
        top: rem(-1);
    }

    .c-headeractions__account {
        margin-top: rem(-7);

        @include breakpoint (medium) {
            margin-top: rem(-4);
        }

        svg {
            height: rem(28);
            width: rem(28);

            @include breakpoint (medium) {
                height: rem(24);
                width: rem(24);
            }
        }

        .c-headeractions__icon--logged-in,
        .c-headeractions__icon--logged-out {
                fill: none;
                color: #fff;

            @media (pointer: fine) {
                transition: color 0.3s ease-in-out;
                &:hover {
                    color: cssvar(color-secondary);
                }
            }
        }
    }

    .c-headeractions__search-button,
    .c-headeractions__account {

        @media (pointer: fine) {
            &:hover {
                fill: cssvar(color-secondary);
            }
        }
        &::after {
            display: none !important;
        }
    }
}
