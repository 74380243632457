.c-embed {
    margin: 0 0 1.625rem;

    // position: relative;
    // &:before {
    //     content: "";
    //     display: block;
    //     position: absolute;
    //     top: 0;
    //     right: 0;
    //     left: 0;
    //     bottom: 0;
    //     border: 1px solid red;
    //     z-index: 99999999;
    //     pointer-events: none;
    // }

    >* {
        transition: opacity 0.3s ease-in-out;
    }

    xmp {
        display: none;
    }

    .uc-heading {
        font-size: 1.1rem;
        color: currentColor;
        font-weight: 700;
        margin: 1.5rem 0 0.5rem;
    }

    a {
        color: cssvar(color-primary);

        @include tpl(rockantenne) {
            color: cssvar(color-white);
        }
    }

    .c-embed__title {
        font-size: 1.5rem;
        line-height: 1.4;
        color: currentColor;
        font-weight: 700;
        margin-bottom: 1rem;
    }

    .c-embed__preview {
        display: block;
        border: 1px solid cssvar(color-primary);
        padding: 1rem;
        background: #f4f6f9;
        color: cssvar(color-primary);

        @include tpl(rockantenne) {
            background-color: cssvar(color-mediumblue);
            border-color: cssvar(color-mediumblue);
        }

        @include tpl(oldieantenne) {
            background-color: cssvar(form-bg);
            border-color: cssvar(color-secondary);
        }

        @include breakpoint (medium) {
            padding: 2rem;
        }
    }

    .c-embed__preview.hide {
        display: none;
    }

    .c-embed__optinbutton {
        .uc-embed {
            display: none !important;
        }

        .uc-optInCheckboxWithLabel.uc-title {
            display: inline-block;
            margin: 0 0;
            padding: 0;
            font-size: 1rem;
            font-weight: 700;
        }
    }

    .c-embed__processordetails {
        display: none;
        margin-top: 1.5rem;

        .uc-optInCheckboxWithLabel {
            display: none !important;
        }
    }

    .c-embed__details:target .c-embed__processordetails {
        display: block;
    }

    .c-embed__details:target .c-embed__processorlink,
    .c-embed__details:target .c-embed__processor {
        display: none;
    }

    .c-embed__processor {
        min-height: 54px;
    }

    .c-embed__processor,
    .c-embed__processor .uc-heading {
        font-size: 13px;
        line-height: 1.4;
        margin: 0;
    }

    .c-embed__details .uc-furtherInformation {
        font-size: 15px;
    }

    .c-embed__live {
        display: none;

        iframe {
            background: #fff; // at least needed for facebook embed, lets assume all websites rely on white pages
            border: none;
        }

        // DPA Sport Widget Embed
        div[data-app="dpa-sportslive"] {
            margin: -5px -15px; // hard-coded pixel, to match dpa-embed.scss
            iframe {
                background: transparent;
            }
        }
        // DPA Sport "Stadien" Embed
        div[data-app="dpa-addons"] {
            margin: 0 -12px;
        }
    }

    &.is-initialized .c-embed__live {
        display: block;
    }

    &--youtube, &.is-video {
        .c-embed__live {
            position: relative;
            height: 0;
            margin-bottom: 1rem;
            padding-bottom: var(--aspectRatio, 56.25%);
            overflow: hidden;
        }

        .c-embed__live>iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    &--twitter .c-embed__live iframe {
        width: 100% !important;
    }

    &--facebook .c-embed__live iframe {
        max-width: 100% !important;
    }
}

html.embeds-consent-available .c-embed {
    background: transparent;
}

html:not(.embeds-consent-available) .c-embed>* {
    opacity: 0;
}
