.c-playlist {
    margin: rem(25 0);

    ul {
        list-style: none;
    }

    li {
        position: relative;
        margin-bottom: rem(10);
        display: flex;
        align-items: center;

        &:first-child {
            .icon--dot::before {
                display: none;
            }
        }

        &:last-child {
            .icon--dot::after {
                display: none;
            }
        }
    }

    // circle
    .icon--dot {
        flex: 0 0 auto;
        width: rem(5);
        height: rem(5);
        margin: rem(0 5);
        border-radius: rem(16);
        background-color: cssvar(color-primary);

        @include breakpoint(medium) {
            margin: rem(0 20);
        }

        @include tpl(rockantenne) {
            background-color: cssvar(color-bluegray);
        }

        &::before {
            content: '';
            position: absolute;
            bottom: calc(50% + rem(2.5));
            width: rem(1);
            height: calc(50% - rem(2.5));
            transform: translateX(rem(2));
            background-color: cssvar(color-bluegray);
        }

        &::after {
            content: '';
            position: absolute;
            top: calc(50% + rem(2.5));
            width: rem(1);
            height: calc(50% + rem(7.5));
            transform: translateX(rem(2));
            background-color: cssvar(color-bluegray);
        }
    }

    &__time {
        //important so lines between dots are exactly lined up
        min-width: rem(40);
    }

    &__content {
        width: 100%; // lets grid fill all empty spacing

        .l-grid {
            flex-wrap: nowrap;
        }
    }

    &__favorite-icon {
        max-width:rem(22);
        max-height: rem(22);
        width: auto;
        height: 100%;
    }
}
