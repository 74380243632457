.c-rating {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 0 $global-margin;

    &__stars {
        --percent: calc(var(--rating) / 5 * 100%);
        font-family: Times, sans-serif; // make sure ★ appears correctly
        line-height: 1;
        margin: rem(-2 0 0 -4);
        padding: rem(0 10 0 0);

        font-size: rem(31);
        @include container(rem(160), card, down) {
            font-size: rem(24);
        }

        &::before {
            content: '★★★★★';
            letter-spacing: 1px;
            background: linear-gradient(90deg, cssvar(color-secondary) var(--percent), cssvar(color-lightgray) var(--percent));
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
    }

    &__rate {
        flex: 0 0 auto;
        font-size: rem(23);
        font-weight: bold;
        padding: rem(0 10 0 0);
        text-align: left;
    }

    &__count {
        padding: rem(3 0);
        line-height: 1.1;
        flex-basis: 100%;
    }
}
