.c-mainnav {
    font-family: cssvar(header-font-family);
    letter-spacing: cssvar(header-font-spacing);

    ul {
        display: flex;
    }

    li {
        flex: 0 0 auto;
    }
}
