.c-image {
    position: relative;
    display: block;
    width: var(--width, 100%);
    max-width: 100%;
    margin: 0 0 $global-margin;
    overflow: hidden; // needed for border-radius for antenne

    img,
    picture {
        display: block;
        width: var(--width, 100%);
        max-width: 100%;
        height: auto;
        background-color: cssvar(color-lightestgray);
        aspect-ratio: var(--aspectratio, 16 / 9);
    }

    &--content {

        img,
        picture {
            @include tpl(antenne) {
                border-radius: $global-radius;
            }
        }
    }

    &--center {
        margin-right: auto;
        margin-left: auto;
    }

    &--right {
        margin-right: 0;
        margin-left: auto;
    }

    &__flag {
        position: absolute;
        left: 0;
        top: 0;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: cssvar(color-primary-80);
        font-size: rem(12);
        line-height: rem(16);
        color: cssvar(color-white);
        text-align: center;

        @include tpl(antenne) {
            padding: rem(8 24);
            border-radius: rem(6 0 6 0);
        }

        @include tpl(oldieantenne) {
            padding: rem(8 24);
        }

        @include tpl(rockantenne) {
            padding: rem(12 43 11 38);
            background: cssvar(color-secondary-80);
            clip-path: polygon(0 0, 100% 0%, 90% 100%, 0% 100%);
            font-family: cssvar(header-font-family);
            letter-spacing: cssvar(header-font-spacing);
        }
    }

    small:not(.c-image__flag) {
        display: block;
        margin: 5px 0 0 0;
        font-size: rem(12);

        @include tpl(rockantenne) {
            text-align: right;
        }
    }
    figcaption {
        display: block;
        margin-top: rem(5);
    }
}
