/* ==========================================================================
   Global object classes
   ========================================================================== */

.o-progress {
    width: 100%;
    height: rem(4);
    border-radius: $global-radius;

    &::-webkit-progress-bar {
        background: #0F172A1A;
    }

    &::-webkit-progress-value {
        background: cssvar(color-primary);
    }

    @include tpl(rockantenne) {
        border-radius: 0;

        &::-webkit-progress-bar {
            background:rgba(255, 255, 255, 0.1);
        }
        &::-webkit-progress-value {
            background: cssvar(color-white);
        }
    }
}

.o-audioplayback-status {
    color: cssvar(color-bluegray);

    @include tpl(rockantenne) {
        color: cssvar(color-mediumgray);
    }
}

.o-brushbg {
    display: table;
    position: relative;

    span {
        position: relative;
    }

    &:before {
        content: "";
        background: url(brush-background.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center 1px;
        position: absolute;
        top: -0.4em;
        bottom: -0.3em;
        left: -0.9em;
        right: -1em;
    }
}

.o-trafficsymbol {
    padding: rem(2 11);
    font-weight: $global-weight-bold;
    border: rem(2) solid currentColor;

    @include tpl(antenne) {
        border-radius: rem(2);
    }
}

//pie Timer
.o-pietimer svg {
    display: block;
    background: cssvar(color-primary);
    border-radius: 50%;
    transform: rotate(-90deg);
}

.o-pietimer circle {
    fill: cssvar(color-primary);
    stroke: cssvar(color-secondary);
    stroke-width: 16;
    stroke-dasharray: 0 50.2654824574; /* PI x stroke-width */
}
.o-pietimer.u-transition circle {
    transition: stroke-dasharray 15s linear;
}

// Badge for image. Used for status in RA concert calendar
.o-image-badge {
    position: absolute;
    right: rem(30);
    bottom: 25%;
    padding: rem(3 20 1);
    background-color: cssvar(color-primary-85);
    border-radius: rem(8);
    font-size: rem(16);
    font-weight: bold;
    font-style: italic;
    letter-spacing: rem(1);
    text-transform: uppercase;

    &--large {
        // This version is used in the main key visual on a concert detail page
        @include breakpoint(large) {
            right: 8%;
            font-size: rem(40) !important;
        }
    }

    @include tpl(rockantenne) {
        text-shadow: 1px 1px cssvar(color-secondary);
    }

    @include tpl(antenne) {
        color: cssvar(color-white);
    }
}

// Aligns icon vertically centered with text. Can be used on a paragraph tag with an svg inside of it.
.o-text-w-icon {
    display: flex;
    align-items: center;
    svg {
        margin-left: rem(5);
    }
}
