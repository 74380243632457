/* ==========================================================================
   Skeleton loading
   - Used when we load components via javascript
   - Background info: https: //css-tricks.com/building-skeleton-components-with-react/
   ========================================================================== */

.is-skeleton {
    position: relative;
    background: cssvar(color-background);
    // We don't want any clicks on skeletons
    >*:not([data-bookmark-noresults-link]) {
        pointer-events: none;
    }

    @include setcssvar('skeleton-bg', var(--skbkgd, #f1f1f1));

    @include tpl(rockantenne,oldieantenne) {
        @include setcssvar('skeleton-bg', var(--skbkgd, #B3BCCC));
        // Background gradient on sliders here need to be hidden
        .c-slider::after, .c-slider::before{
            background: transparent
        }
    }

    @include tpl(oldieantenne) {
        @include setcssvar('skeleton-bg', var(--skbkgd, #343434));
    }

    // Gradient animation overlay
    &.is-loading:after {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 99;
        content: '';
        // per definition, a transparent black/gray would have no effect with `mix-blend-mode: color-burn` which
        // is why we use red (255,0,0) and grayscale here. Needed for safari, but its not a bug. All others are incorrect.
        background:linear-gradient(270deg, rgba(255, 255, 255, 0) 30%, rgba(255, 0, 0, 0.6) 45%, rgba(0, 0, 0, 0) 60%);
        filter: grayscale(1);
        animation: skeletonAnimate 1.75s ease-in-out infinite;
        background-size: 300% 100%;
        mix-blend-mode: color-burn;
    }

    img::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        inset: 0;
    }

    p,
    img,
    img::before,
    .o-headline,
    .c-toolbar svg,
    .c-cover img::before,
    .c-badge,
    .c-badge__number,
    .c-card--geo__city,
    .c-card--geo__type {
        color: transparent !important;
        background: cssvar(skeleton-bg);
        min-height: calc(var(--sklines, 1) * 1em * 1.4);
    }

    @include tpl(antenne) {
        .u-separator {
            background: cssvar(skeleton-bg);
        }
    }

    .c-card .c-tooltip__icon--dots, .c-icon {
        fill: cssvar(skeleton-bg);
    }

    .c-cover {
        box-shadow: none;
    }

    .c-cover__button--favorite {
        display: none;
    }

    .c-slider__control {
        display: none;
    }

    &.is-skeleton--emptybookmarks {
        @include setcssvar('skeleton-bg', cssvar(color-primary));

        .c-cover__button--favorite {
            display: block;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;

            svg {
                width: 40%;
                height: 40%;
            }
        }


        // Articles bookmarks logic: Hide our article slider and show our default content
        &[data-bookmarkcategory="articles"] {

            .l-cardgrid {
                display: none;
            }

            .is-default-content {
                // overrides u-hide
                display: block !important;
            }
        }

        // We want to show our default content in the case of no bookmarks in audio widget
        .is-default-content {
            p {
                color: cssvar(primary-color) !important;
                background-color: transparent;
            }
        }
    }
}

@keyframes skeletonAnimate {
    0%, 25% {
        background-position: 0% 0%
    }
    100% {
        background-position: 100% 0%
    }
}
