@mixin cardslider {
    margin-left: calc(cssvar(content-gutter-left) * -1);
    margin-right: calc(cssvar(content-gutter-right) * -1);

    .l-cardgrid__items {
        flex-wrap: nowrap;
        margin: 0 0 -1rem;
        padding: 0 cssvar(content-gutter-right) 1rem 0;

        overflow-x: scroll;
        -webkit-overflow-scrolling: touch;
        scroll-snap-points-x: repeat(100%);
        scroll-snap-type: x mandatory;
        scroll-behavior: smooth;
        scroll-padding: cssvar(content-gutter-right);

        // hide scrollbar
        -ms-overflow-style: none; // IE 10+
        scrollbar-width: none; // Firefox

        &::-webkit-scrollbar {
            display: none; // Safari and Chrome
        }

        > .l-cardgrid__item:first-child {
            margin-left: cssvar(content-gutter-left);
        }

        > .l-cardgrid__item:last-child {
            // due to a safari rendering bug, the content-gutter-right is added as padding to .l-cardgrid__items
            margin-right: 0;
        }

        .l-cardgrid__item {
            scroll-snap-align: start;
        }

        &.mousedown {
            scroll-snap-type: unset;
            scroll-behavior: unset;
        }

    }
}

@mixin tooltipDown {
    padding-bottom: rem(26);

    .l-grid--cardheader .l-grid__cell--shrink {
        width: 0;
        margin: 0;
    }

    .c-tooltip {
        position: absolute;
        bottom: rem(10);
        left: rem(17.5);
    }
}

.l-cardgrid {
    position: relative;
    --cardwidth: 100;

    &__items {
        display: flex;
        flex-flow: row wrap;
        position: relative;
        min-width: 100%;
        height: auto;
        margin-left: calc(cssvar(grid-gutter) / -2);
        margin-right: calc(cssvar(grid-gutter) / -2);
    }

    // the full selector is intended here, so styles only apply if __item is inside l-cardgrid
    .l-cardgrid__item {
        flex: 0 0 auto;
        margin-left: calc(cssvar(grid-gutter) / 2);
        margin-right: calc(cssvar(grid-gutter) / 2);
        width: calc(var(--cardwidth, 100) * 1% - cssvar(grid-gutter));
        --cardminheight: calc(100% - #{cssvar(grid-gutter)});

        @include breakpoint(medium) {
            &--medium {
                --cardwidth: 50;
            }

            &--small {
                --cardwidth: 33.301; // should not be 33.333 because with 301 we render full-pixel-width cards (321px)
            }
        }

        @include breakpoint(large) {
            &--medium-for-large {
                --cardwidth: 50;
            }
        }

        &--hidden {
            position: absolute;
            padding: 0;
            clip: rect(0, 0, 0, 0);
            white-space: nowrap;
            opacity: 0;
            max-height: 0px !important;
        }

        &--fadein {
            position: relative;
            clip: initial;
            white-space: inherit;

            transition: max-height ease-in-out 0.3s;
            animation: fade-in-move-up 0.7s;
            max-height: 500px; // TODO: set custom prop via JS to use here the real rendered height value

            .c-cardgrid--largeslider &,
            .c-cardgrid--smallslider & {
                animation: fade-in-move-left 0.7s;
                transition: none;
            }

            @include breakpoint(medium) {
                transition: max-height ease-in-out 0.3s;
                animation: fade-in-move-up 0.7s;
            }
        }
    }

    @include breakpoint(medium, down) {
        &--smallslider,
        &--largeslider {
            @include cardslider;
        }

        &--largeslider .l-cardgrid__item,
        &--smallslider .l-cardgrid__item {
            --cardwidth: 43 !important;
        }
    }
    @include breakpoint(medium, down) {
        &--largeslider .l-cardgrid__item {
            --cardwidth: 85 !important;
        }
    }

    &--desktopslider {
        @include cardslider;

        @include breakpoint(medium) {
            .l-cardgrid__items {

                // mask-image is added "centrally" in _slider.scss !
                .l-cardgrid__item {
                    --cardwidth: 30 !important;
                }
            }
        }
    }

    &__loadmorebutton.c-button {
        // needed to increase specifiy for this selector in order to work
        display: none;
        margin: 0 auto $global-margin;
    }

    &.l-cardgrid--noslider.has-loadmore .l-cardgrid__loadmorebutton {
        display: flex;
    }

    @include breakpoint(medium) {
        &.has-loadmore .l-cardgrid__loadmorebutton {
            display: flex;
        }
    }
}

.u-cardsfullheight {
    --cardminheight: calc(100% - #{cssvar(grid-gutter)});
}

.c-card {
    // set foreground color
    @include setcssvar(color-foreground, cssvar(form-accent, cssvar(color-primary)));
    @include setcssvar(color-cardbg, cssvar(form-bg, cssvar(color-white)));

    container: card / inline-size;
    position: relative;
    margin: 0 0 cssvar(grid-gutter);
    box-shadow: $box-shadow;
    border-radius: $global-radius;
    background-color: cssvar(color-cardbg);
    color: cssvar(color-foreground);
    overflow: hidden;

    width: 100%;
    min-height: var(--cardminheight, 0);

    .l-grid {
        @include setcssvar(grid-gutter, cssvar(grid-gutter-override, rem(35)));
    }

    .c-card__media {
        position: relative;
        width: var(--card-media-width, 100%);
        margin: 0;
    }

    .c-card__content {
        margin-top: calc(#{cssvar(grid-gutter)} / 2);
        flex: 1 1 0;
        width: auto;
        align-self: center;

        &:has(.c-tooltip) {
            @include container(rem(300), card, down) {
                @include tooltipDown;
            }
        }
    }

    .o-headline {
        text-wrap: balance;
        --maxlines-small: 3;
        @include container(rem(420), card, up) {
            --maxlines-small: 2;
        }
    }

    .c-card__text {
        text-wrap: pretty;
        --maxlines-small: 5;
        @include container(rem(300), card, up) {
            --maxlines-small: 4;
        }
        @include container(rem(450), card, up) {
            --maxlines-small: 3;
        }
    }

    // used for the large audio cards on the home page and login dashboard
    &--largegutter .l-grid {
        @include breakpoint(medium) {
            @include setcssvar(grid-gutter, rem(50));
        }
    }

    &--content-right, &--content-left {

        > .l-grid {

            @include container(rem(700), card) {

                --card-media-width: calc(62.2% - #{cssvar(grid-gutter)});

            }

            @include container(rem(900), card) {
                --card-media-width: calc(66% - #{cssvar(grid-gutter)});
            }

        }

        @include container(rem(700), card) {
            &:has(.c-tooltip) .c-card__content {
                @include tooltipDown;
            }
        }

        .o-headline {
            @include container(rem(900), card, up) {
                --maxlines-small: 3;
            }
        }

        .c-card__text {
            --maxlines-small: 5;
            @include container(rem(300), card, up) {
                --maxlines-small: 4;
            }
            @include container(rem(900), card, up) {
                --maxlines-small: 6;
            }
        }
    }

    &--content-right {

        @include container(rem(700), card) {
            &:has(.c-tooltip) .c-card__content .c-tooltip {
                left: calc(var(--card-media-width, 0) + #{cssvar(grid-gutter)} / 2);
            }
        }

    }
    &--content-left {

        @include container(rem(700), card) {
            .c-card__content {
                order: -1;
            }
        }
    }

    // Regwall handling
    &__regwall-icon {
        display: none;
        position: absolute;
        top: rem(10);
        right: rem(15);
        width: rem(17);
        height: rem(19);
        fill: cssvar(color-primary);
    }
    /*
    * In case we need to handle other styling options for a regwall card,
    * using a state-based css structure. This class gets added via js.
    */
    &.has-regwall {
        .c-card__regwall-icon {
            display: block;
        }
    }

    // ANTENNE TEMPLATE ONlY: Hover effect only on cards that have an overlay link
    // Note as of now, Firefox does not support :has
    @include tpl(antenne) {
        &:has(.u-faux-block-link-overlay) {
            .u-separator {
                transition: width 0.3s ease-in-out;
                max-width: rem(230);
            }

            &:hover .u-separator {
                width: 100%;
            }
        }
    }

    &--toolbar {
        // @include tpl(antenne) {
        //     .c-toolbar {
        //         margin-left: rem(5);
        //     }
        // }

        @include tpl(rockantenne) {
            // Needed to add no background color to toolbar area
            background-color: transparent;

            // Adds background color to text area
            > .l-grid {
                background-color: cssvar(color-cardbg);
            }
        }
    }

    &--alternative-bg {
        @include setcssvar(color-foreground, cssvar(color-white));
        // skeleton background var
        --skbkgd: #084685;
        background: linear-gradient(
            148deg,
            rgba(26, 106, 169, 1) 0%,
            rgba(0, 72, 152, 1) 41%,
            rgba(26, 106, 169, 1) 100%
        );

        @include tpl(rockantenne) {
            --skbkgd: #83929b;
            background: linear-gradient(
                148deg,
                rgb(131, 146, 155) 0%,
                rgb(97, 102, 106) 41%,
                rgb(131, 146, 155) 100%
            );
        }

        @include tpl(oldieantenne) {
            background: linear-gradient(
                148deg,
                rgb(29 29 29) 0%,
                rgb(47 47 47) 41%,
                rgb(30 30 30) 100%
            );
        }
    }
}


.c-card--quote {

    // No image settings
    >.l-grid {
        --card-media-width: 15%;

        @include container(540, card, up) {
            --card-media-width: 15%;
        }
        // Never showing this quote icon
        .c-card__quote-icon--secondary {
            display: none;
        }
    }

    // Image settings
    >.l-grid.has-image {
        --card-media-width: 20%;

        .c-image {
            display: none;
        }

        @include container(680, card, up) {
            --card-media-width: 38%;

            .c-image {
                display: block;
            }

            .c-card__quote-icon--secondary {
                display: block;
            }

            .c-card__quote-icon--standard {
                display: none;
            }

            .c-card__content {
                padding: rem(0 30);
            }
        }
    }

    .c-card__quote-icon--secondary {
        fill: cssvar(color-secondary);
        position: absolute;
        z-index: 1;
        left: 35%;
        width: 100%;
        height: auto;
        max-width: rem(80);
        max-height: rem(80);
    }

    .c-card__quote-icon--standard {
        width: 100%;
        height: auto;
        max-width: rem(60);
        max-height: rem(60);
        margin: rem(20 10);
        @include container(540, card, up) {
            margin: rem(20);
        }
    }

    p {
        position: relative;
        z-index: 2;
    }
}


.c-card--audio {
    > .l-grid {
        --card-media-width: 46%;
        @include container(330, card, up) {
            --card-media-width: 45%;
        }
        @include container(850, card, up) {
            --card-media-width: 33%;
        }
    }

    .c-cover {
        border-radius: 0;
        box-shadow: none;
    }

    .c-card__content {
        margin-top: rem(10);
    }

    .o-headline {
        --min-font-size: 19;
        --max-font-size: #{cssvar(fontSize-4)};
        --maxlines-small: 2;
    }

    .c-audiocard__description {
        --maxlines-small: 5;
        @include container(rem(570), card, down) {
            --maxlines-small: 4;
        }
        @include container(rem(570), card, down) {
            --maxlines-small: 3;
        }
        @include container(rem(470), card, down) {
            --maxlines-small: 2;
        }
    }

    .c-audiocard__claim {
        --maxlines-small: 2;
        margin-bottom: 0;
    }

    @include container(rem(570), card, down) {
        .o-headline {
            --min-font-size: 17;
            --max-font-size: #{cssvar(fontSize-1)};
        }
        .c-audiocard__currentlyplaying {
            display: none;
        }
    }

    @include container(rem(390), card, down) {
        .u-separator {
            display: none;
        }
    }

    &:has(.c-audiocard__claim) {
        @include container(rem(462), card, down) {
            .c-audiocard__description {
                display: none;
            }
        }

        @include container(rem(462), card, up) {
            .c-audiocard__claim {
                display: none;
            }
        }
    }

}

.c-card--episode {
    $cardBreakpoint1: rem(660);
    $cardBreakpoint2: rem(916);

    > .l-grid {
        --card-media-width: #{rem(150)};
        @include container($cardBreakpoint1, card, up) {
            --card-media-width: 33%;
        }

        @include container($cardBreakpoint2, card, up) {
            --card-media-width: 20%;
        }
    }

    .c-cover {
        margin: rem(18 0 0 18);

        @include container($cardBreakpoint1, card, up) {
            border-radius: 0;
            margin: 0;
        }
    }

    .c-card__content {
        flex: 0 0 auto;
        width: calc(100% - var(--card-media-width) - #{cssvar(grid-gutter)});

        @include container($cardBreakpoint1, card, up) {
            margin-top: rem(10);
        }
    }

    .c-episodecard__smallcontent {
        width: calc(100% - #{cssvar(grid-gutter)});

        @include container($cardBreakpoint1, card, up) {
            display: none;
        }
    }

    .c-episodecard__largecontent {
        @include container($cardBreakpoint1, card, down) {
            display: none;
        }
    }

    .o-headline {
        --maxlines-small: 5;
        @include container($cardBreakpoint1, card, up) {
            --maxlines-small: 2;
        }
        @include container($cardBreakpoint2, card, up) {
            --maxlines-small: 1;
        }
    }

    .c-episodecard__description {
        --maxlines-small: 6;
        @include container($cardBreakpoint1, card, up) {
            --maxlines-small: 3;
        }
    }
}


// Geolocation Variation. Cards found on the bottom of the "für dich" section of the homepage.
.c-card--geo {
    &__content-wrapper {
        margin: cssvar(grid-gutter);
    }

    &__header {
        display: flex;
        justify-content: space-between;
        margin-bottom: $global-margin;

        @include breakpoint(medium) {
            display: block; // on medium screens, send the weather city info underneath the heading.
        }

        @include breakpoint(large) {
            display: flex;
        }
    }

    &__heading {
        text-transform: uppercase;
        margin: 0 calc(cssvar(grid-gutter) * 2) 0 0;
    }

    &__content {
        display: flex;
        align-items: center;
        margin-bottom: calc($global-margin / 2);

        @include breakpoint(medium) {
            flex-direction: column;
            margin-bottom: $global-margin;
        }

        @include breakpoint(large) {
            flex-direction: row;
        }
    }

    &__main-icon {
        margin-right: rem(25);
        margin-left: cssvar(grid-gutter);

        @include breakpoint(medium) {
            flex: 1 0 100%;
            margin-bottom: $global-margin;
        }

        @include breakpoint(large) {
            flex: 0 0 auto;
            margin-right: cssvar(grid-gutter);
            margin-left: 0;
            margin-bottom: 0;
        }

        img,
        svg {
            stroke: currentColor;
            width: rem(40);
            height: rem(40);

            @include breakpoint(medium) {
                width: rem(58);
                height: rem(58);
            }
        }
    }

    &__footer {
        display: flex;
        justify-content: right;

        a {
            display: flex;
            align-items: center;
            text-decoration: none;
            word-break: break-word;
        }

        div {
            // link label. Span is used inside of this to hide text for medium screens.
            display: inline;
            margin-right: rem(10);
        }

        svg {
            // arrow icon
            height: rem(13);
            width: rem(14);
        }
    }

    // Weather Widget Items

    &__city {
        display: flex;
        align-items: center;

        span {
            // Our city name
            margin-left: rem(5);
        }

        svg {
            // Location icon
            width: rem(19);
            height: rem(19);
        }
    }

    &__temp {
        margin: 0;
        font-size: rem(48);
        font-family: cssvar(header-font-family);
        letter-spacing: cssvar(header-font-spacing);
        font-weight: 700;
        line-height: 1;
    }

    // Traffic Widget Items

    &__traffic-list {
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;

        li {
            flex: 1 0 33%;
            margin-bottom: rem(10);
        }
    }

    // Blitzer (Speed cameras)

    &__speedcamera-content {
        max-height: rem(68);
        overflow: hidden;
        margin-bottom: rem(5);
    }
}
