.c-contentplaybar {
    position: relative;
    margin: rem(10 0);
    color: cssvar(color-white);

    --boxHeight: #{rem(55)};

    @include breakpoint(medium) {
        --boxHeight: #{rem(64)};
    }

    @include tpl(rockantenne) {
        margin-bottom: rem(-7);
    }

    &__text-wrapper {
        display: flex;
    }

    &__text {
        z-index: 1;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        height: var(--boxHeight);
        margin: 0 0 0 rem(-3);
        padding: rem(7 7 7 25);
        background: cssvar(color-primary-80); //linear-gradient(to right, transparent rem(29.9), cssvar(color-primary-80) rem(30));
        backdrop-filter: blur(rem(4));
        overflow: hidden;

        mask: url('data:image/svg+xml;utf8,<svg preserveAspectRatio="xMinYMid meet" width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="-22" cy="32" r="39" fill="black"/></svg>') 0/100% 100%, linear-gradient(#fff, #fff);
        mask-composite: exclude;

        @include breakpoint(medium) {
            padding-left: rem(35);
        }

        @include tpl(antenne) {
            border-radius: 0 $global-radius $global-radius 0;
        }

        @include tpl(rockantenne) {
            background: cssvar(color-gray-60);
        }

        @include tpl(rockantenne) {
            background: rgba(17, 17, 17, 0.2);
        }

        p {
            font-size: rem(16);
            line-height: rem(19);
            white-space: nowrap;

            @include breakpoint(medium) {
                font-size: rem(18);
                line-height: rem(20);
            }
        }
        // Class used for the greeting text in the welcome playbar component
        .o-headline--size6 {
            white-space: nowrap;
        }
    }

    &--main {
        .c-contentplaybar__text {
            background: cssvar(color-primary);

            @include tpl(rockantenne, oldieantenne) {
                background: cssvar(color-darkgray);
            }

            [data-scrolltext] strong {
                display: block;

                @include breakpoint (medium) {
                    display: inline;
                }
            }
        }
    }

    &__sidecar {
        width: rem(50);
        height: var(--boxHeight);
        padding: rem(3 0); // vertical padding for mobile
        background: cssvar(color-primary-80);
        backdrop-filter: blur(rem(4));

        @include tpl(antenne) {
            border-radius: $global-radius;
        }

        @include tpl(rockantenne) {
            background: cssvar(color-gray-60);
        }

        @include tpl(rockantenne) {
            background: rgba(17, 17, 17, 0.2);
        }

        @include breakpoint(medium) {
            width: rem(120);
        }
    }
}

.c-miniweather {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    height: 100%;
    justify-content: space-evenly;
    align-items: center;
    text-decoration: none;
    animation: 0.3s ease-in-out fadeIn;

    @include breakpoint(medium) {
        flex-direction: row-reverse;
    }

    &__icon {
        fill: currentColor;
        stroke: currentColor;
        display: block;
        width: rem(22);
        height: auto;
        margin: 0;
        padding: 0;
        aspect-ratio: 1 / 1;
        overflow: hidden; // in case src is not found, to prevent overflow

        @include breakpoint(medium) {
            width: rem(38);
        }
    }

    &__temperature {
        font-size: rem(17);
        font-weight: $global-weight-bold;
        line-height: 1;
        padding-left: rem(4); // adjustment visually needed due to ° character

        @include breakpoint(medium) {
            font-size: rem(28);
            padding-left: 0;
        }
    }
}
