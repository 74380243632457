/* ==========================================================================
   Slider Component
   - Used for slider with covers and inside of audio widgets
   - is enhanced by the slider controls js
   - Markup expected to be ul > li
   ========================================================================== */

// gradients on the sides to cover prev/next slide on larger screens
// used here and for l-cardgrid
.c-slider .c-slider__track,
.l-cardgrid--desktopslider .l-cardgrid__items {
    @include breakpoint(large) {
        mask-image: linear-gradient(to right, rgba(0, 0, 0, 0) rem(14), rgba(0, 0, 0, 1) rem(28), rgba(0, 0, 0, 1) calc(100% - #{rem(28)}), rgba(0, 0, 0, 0) calc(100% - #{rem(14)}));
    }
}

.c-slider {
    // TODO: content-gutter is just used with left here, should include right too
    @include setcssvar(slider-margin, cssvar(content-gutter-left));
    @include setcssvar(slider-item-gutter, rem(16));

    position: relative;
    margin: 0 calc(cssvar(slider-margin) * -1) $global-margin;

    &--ingrid {
        @include setcssvar(slider-margin, calc(cssvar(grid-gutter) / 2));
    }

    &__track {
        // ul
        list-style: none;
        flex-flow: row nowrap;
        position: relative;
        display: flex;
        width: 100%;
        height: auto;
        margin: 0 0 -1rem;
        padding: 0 cssvar(slider-margin) 1rem 0;

        // to show visually that the user can scroll with mouse
        cursor: all-scroll;

        // Slider options
        overflow-x: scroll;
        -webkit-overflow-scrolling: touch;
        scroll-snap-points-x: repeat(100%);
        scroll-snap-type: x mandatory;
        scroll-behavior: smooth;
        scroll-padding: cssvar(slider-margin);

        // hide scrollbar
        -ms-overflow-style: none; // IE 10+
        scrollbar-width: none; // Firefox

        &::-webkit-scrollbar {
            display: none; // Safari and Chrome
        }

        &.mousedown {
            scroll-snap-type: unset;
            scroll-behavior: unset;
        }

        // gradients on the sides to cover prev/next slide on larger screens
        @include breakpoint(large) {
            mask-image: linear-gradient(to right, rgba(0, 0, 0, 0) rem(14), rgba(0, 0, 0, 1) rem(28), rgba(0, 0, 0, 1) calc(100% - #{rem(28)}), rgba(0, 0, 0, 0) calc(100% - #{rem(14)}));
        }
    }

    &__item {
        // li
        scroll-snap-align: start;
        margin: 0 calc(cssvar(slider-item-gutter) / 2) 0;
        padding: 0;

        @include setcssvar("slide-count", var(--slide-count, 1.075));

        @include breakpoint(medium) {
            @include setcssvar("slide-count", var(--slide-count-medium, var(--slide-count, 1.075)));
        }

        @include breakpoint(large) {
            @include setcssvar("slide-count", var(--slide-count-large, var(--slide-count, 1)));
        }

        flex: 0 0 calc((100% // our outer slider margin left AND right
                - (cssvar(slider-margin)) // slider-count minus 1 times our gutter
                - (cssvar(slider-item-gutter) * (cssvar(slide-count) - 1))) / cssvar(slide-count, 1));

        &:first-child {
            margin-left: cssvar(slider-margin);
        }

        &:last-child {
            // due to a safari rendering bug, the content-gutter-right is added as padding to .l-cardgrid__items
            margin-right: 0;
        }
    }

    &__control {
        z-index: 14;
        position: absolute;
        top: 48%;
        transform: translateY(-50%);
        display: none;
        padding: rem(8);
        margin: 0;
        background: none;
        border: none;
        cursor: pointer;
        line-height: normal;

        @media (hover: none) {
            display: none !important;
        }

        @include breakpoint(large) {
            display: block;
        }

        svg {
            width: rem(11);
            height: rem(19);
            fill: cssvar(color-primary);

            @include tpl(rockantenne) {
                fill: cssvar(color-white);
            }
        }

        &--prev {
            left: 0;
        }

        &--next {
            right: 0;
        }

        &.is-disabled {
            pointer-events: none;
            opacity: 0.4;
        }
    }

    &__dots {
        display: flex;
        justify-content: center;
        list-style: none;
        padding: 0;
        position: absolute;
        bottom: rem(20);
        width: 100%;

        li {
            flex: 0 0 auto;
            margin-right: rem(10);

            button {
                display: block;
                width: rem(10);
                height: rem(10);
                margin: 0;
                padding: 0;
                background-color: transparent;
                background-clip: content-box;
                border: 0.25rem solid transparent;
                border-radius: 50%;
                font-size: 0;
                transition: background-color 0.1s;
                border: rem(1) solid cssvar(color-white);

                // TODO: do this only for non-click devices (e.g. keyboard navigation)
                &:focus-visible {
                    border-color: cssvar(color-primary);
                }
            }

            &.is-active button,
            button:hover {
                background-color: cssvar(color-white);
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }

    // found in image galleries
    &__counter {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
            // counter items
            display: block;
        }

        // Button overrides
        .c-slider__control {
            position: relative;
            transform: translateY(0);
            margin: 0 rem(10);

            svg {
                position: relative;
                top: rem(2);
                transform: scale(.7);
            }
        }
    }

    // Overlay text found in Regwall
    &__content-overlay {
        position: absolute;
        bottom: rem(10);
        width: 100%;
        padding: 0 rem(8);

        p {
            line-height: 1.2;
            margin: 0;

            &:last-of-type {
                margin-bottom: $global-margin * 3;
            }
        }
    }

    // Variations
    &--covers {
        --slide-count: 2.3;
        --slide-count-medium: 4.3;
        --slide-count-large: 5;

        .c-cover {
            width: 100%;
        }
    }

    // Pure Image Slider with variable slide width
    // Note: slide-items need `--content-width` and `--content-height` for aspect-ratio calculations
    &--images {

        @include breakpoint(medium) {
            margin-right: calc(cssvar(grid-gutter) * -1);
        }

        $maximumAvailableWidth: calc(var(--grid-cell-width, rem(996)) * 0.9);
        $maximumHeight: calc($maximumAvailableWidth * (9 / 16));

        .c-slider__item {
            // $visibleContentWidth: min(var(--content-width, 100vw), 100vw);
            $aspectRatio: calc(var(--content-height, 9) / var(--content-width, 16));
            $aspectRatioReverse: calc(var(--content-width, 16) / var(--content-height, 9));

            // our maximum image width is 90% of viewport-width, minus our content-gutter (one side only)
            $width: calc((90vw - cssvar(content-gutter-left)));
            // the exact image height is the height of a 16/9 image with our max. image width
            $height: calc($width * (9 / 16));

            --max-width: #{$width};
            @include breakpoint(large) {
                --max-width: #{$maximumAvailableWidth};
            }

            // the exact image width is the height with the aspect ratio of the original image
            // Note: $heigh is calculated without large viewport in mind, which is why we are using min() here
            flex-basis: min(var(--max-width), calc(min($height, $maximumHeight) * $aspectRatioReverse));

            img {
                width: auto;
                max-width: var(--max-width);
                height: $height;
                aspect-ratio: var(--content-width, 16) / var(--content-height, 9);
                object-fit: contain;

                @include breakpoint(large) {
                    max-height: $maximumHeight;
                }
            }
        }
    }

    // Used on the regwall slider
    &--no-sidegradients {
        .c-slider__track {
            mask-image: none;
        }
    }

    &--in-wall {
        max-width: rem(510);
        @include setcssvar(content-gutter-left, 0);
        margin-bottom: 0;

        .c-slider__item {
            min-height: rem(400);
            flex: 1 0 100%;
        }

        img {
            height: auto;
            margin: auto;
            object-fit: cover;
        }

    }
}
