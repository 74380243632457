@mixin adSlotSelector($name) {
    &[class*='#{str-slice(#{&}, 2)}--#{$name}'] {
        @content;
    }
}

@mixin hideCardContent {
    .c-card__content {
        margin-top: rem(10);
        .u-separator {
            margin-top: rem(6);
        }
        p {
            display: none;
        }
    }
}

$skyscaperMediaQueryViewportWidth: 1410px;

body, .l-page-content { // required for skyscaper (sticky)
    @media (min-width: $skyscaperMediaQueryViewportWidth) {
        position: relative;
        overflow: visible !important; // required for position:sticky
    }
}

html.no-ads .o-reklame {
    display: none !important;
}


$addedHeight: 0;

// [data-navhandlersection="reklame-bb"] {
//     // NOTE: If you change this padding, you MUST adjust the margin-top of .c-hero too!
//     padding-bottom: 10px; // needed for box-shadow visibility
// }

@include tpl(oldieantenne) {
    @include breakpoint(large, down) {
        [data-navhandlersection="reklame-bb"] {
            margin-top: 1rem;
            background: cssvar(color-background);
        }
    }
    @include breakpoint(large, up) {
        .o-reklame--bb_1 {
            margin-bottom: 1rem;
        }
    }
}

.o-reklame {
    z-index: 1;
    position: relative;
    width: 100%;
    margin: rem(48) auto 1rem;
    //overflow: hidden; -> removed per Request from symplr 2024-06-25
    // inset box-shadow will be invisible in case an ad slot has been filled
    box-shadow: inset 0 0 0 1px rgb(139 139 139 / 15%);
    background: cssvar(color-background);

    // center ads inside our container
    display: flex;
    justify-content: center;
    align-items: center;

    .adsbygoogle {
        margin: 0 auto;
    }

    &__label {
        display: block;
        position: absolute;
        top: rem(-32);
        left: 0;
        right: 0;
        width: 100%;
        text-transform: uppercase;
        text-align: center;
        font-size: rem(13);
    }

    &:after {
        z-index: -1;
        content: "- Anzeige -";
        position: absolute;
        top: 50%;
        top: calc(50% - 15px);
        width: 100%;
        font-size: rem(13);
        text-align: center;
        text-transform: uppercase;
        color: rgb(139 139 139 / 50%);
    }

    &:has(>div iframe), &.is-filled {
        box-shadow: none; // unset box-shadow to hide border even if ad is small than container
    }

    &:has([data-ad-status="unfilled"]),
    html.debug-ads &.has-error,
    html.debug-ads & {
        // optional styles for non-rendered ads
        &:before {
            z-index: 99999;
            content: attr(class) " | " attr(data-slotid);
            display: block;
            font-size: 11px;
            color: cssvar(color-mediumgray);
            position: absolute;
            bottom: rem(-13);
            right: rem(3);
            line-height: 1;
            text-align: right;
            text-wrap: nowrap;
        }
    }
    html.debug-ads &.has-error:before {
        content: attr(data-slotid) ' (' attr(data-error) ')';
        color: #eecfcf;
    }

    &.is-fallback {
        .c-card {
            max-height: 100%;
        }
    }

    &__fallbackwrapper {
        container: cardwrapper / inline-size;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .l-cardgrid__items & {
        margin-left: calc(cssvar(grid-gutter) / 2);
        margin-right: calc(cssvar(grid-gutter) / 2);
    }

    // Billboard
    &--bb_1 {
        width: fit-content;
        min-width: rem(320);
        max-width: calc(100% - cssvar(content-gutter-right) - cssvar(content-gutter-left));
        --reklame-height: #{rem(100 + $addedHeight)};
        height: var(--reklame-height);

        // margin-bottom needed for box-shadow visibility
        // NOTE: If you change margin-bottom, you MUST adjust the margin-top of .c-hero too!
        margin-bottom: rem(10);

        @include breakpoint(rem(1024)) {
            min-width: rem(970);
            --reklame-height: #{rem(250 + $addedHeight)};
        }

        @include breakpoint(rem(1050)) {
            max-width: calc($global-width - cssvar(content-gutter-right) - cssvar(content-gutter-left));
        }

        &.is-fallback {
            width: auto;
            .c-card:not(.c-card--audio) {
                .c-card__media {
                    --card-media-width: calc(var(--reklame-height) * (16 / 9));
                    // @include container(rem(470), cardwrapper, down) {
                    //     display: none;
                    // }
                }

                @include container(rem(970), cardwrapper, down) {
                    @include hideCardContent;
                }
            }

            .c-card--audio {
                // --card-media-width: calc(var(--reklame-height) - #{rem(15)});
                --antenne-grid-gutter-override: #{rem(25)};
                .l-grid {
                    --card-media-width: var(--reklame-height);
                }
                .u-separator,
                .c-audiocard__description {
                    display: none;
                }
                .c-audiocard__claim {
                    display: block;
                    display: -webkit-box;
                    --maxlines-small: 1;
                    margin-bottom: rem(5);
                }
            }
        }
    }

    // Skyscaper
    &__skywrapper {
        position: absolute;
        top: rem(16);
        height: calc(100% - rem(32));
        left: calc(50% + rem(525));
        width: rem(180);

        display: none;
        @media (min-width: $skyscaperMediaQueryViewportWidth) {
            display: block;

            &:has([data-ad-status="unfilled"]) {
                display: none;
            }
        }

        @media (min-width: 1651px) {
            width: rem(300);
        }

        // Hide skyscapper when billboard is hidden. This happens for Fireplace-Ads
        @at-root .l-page-wrapper:has(.o-reklame--bb_1[style*="display:none"]) &,
        .l-page-wrapper:has(.o-reklame--bb_1[style*="display: none"]) & {
            display: none;
        }
    }
    &--sky_1 {
        position: sticky;
        top: rem(16);
        left: 0;
        display: block;
        width: 100%;
        height: rem(600 + $addedHeight);
        margin: 0;

        &.is-fallback {
            display: none;
        }

        .o-reklame__label  {
            display: none;
        }
    }

    &--sidebar_1 {
        min-width: rem(300);
        height: rem(250);
    }

    @include adSlotSelector('start_') {
        --reklame-height: #{rem(250 + $addedHeight)};
        min-width: rem(300);
        height: var(--reklame-height);
        max-width: 100%;

        @media screen and (min-width: 768px) {
            --reklame-height: #{rem(90 + $addedHeight)};
            width: rem(990);
        }

        &.is-fallback .c-card {
            max-width: rem(300);
            @media screen and (min-width: 768px) {
                max-width: 100%;
                .c-card__media {
                    --card-media-width: calc(var(--reklame-height) * (16 / 9));
                }
            }
            .c-image__flag {
                display: none;
            }
            @include hideCardContent;
        }
    }

    @include adSlotSelector('incontent_') {
        min-width: rem(300);
        height: rem(600 + $addedHeight);

        @include breakpoint(medium) {
            height: rem(250 + $addedHeight);
        }
    }

    @include adSlotSelector('incontentsmall_') {
        width: fit-content;
        min-width: rem(300);
        height: rem(100 + $addedHeight);
        margin-top: 1rem;

        @include breakpoint(medium) {
            min-width: rem(468);
            height: rem(60 + $addedHeight);
        }

        &.is-fallback {
            opacity: 0;
        }
    }

    // incontent_x & sidebar_1 fallback styles (same size)
    @include adSlotSelector('incontent_') {
        @at-root .o-reklame--sidebar_1, & {
            &.is-fallback .c-card {
                max-width: rem(300);

                @include container(rem(654), cardwrapper) {
                    max-width: 100%;
                    .c-card__media {
                        --card-media-width: calc(#{rem(250)} * (16 / 9));
                        @media screen and (min-width: 767px) and (max-width: 769px) {
                            --card-media-width: #{rem(429)};
                        }
                    }
                    .o-headline {
                        --maxlines-small: 3 !important;
                    }
                    p.u-line-clamp {
                        --maxlines-medium: 4 !important;
                    }
                }
            }
        }

        @at-root .o-reklame--sidebar_1.is-fallback .c-card {
            .o-headline {
                --maxlines-small: 2 !important;
            }

            @include container(rem(322), card, down) {
                @include hideCardContent;
            }

            &--audio {
                >.l-grid {
                    --card-media-width: 46%;
                }
                .c-audiocard__claim {
                    --maxlines-small: 1;
                }
            }
        }

        &.is-fallback .c-card {
            @include breakpoint(medium) {
                @include container(rem(322), card, down) {
                    @include hideCardContent;
                }
            }
        }
    }

    &--only-large {
        @include breakpoint(medium, down) {
            display: none;
        }
    }

    &--only-small {
        @include breakpoint(medium) {
            display: none;
        }
    }
}

.o-reklame { // this selector must be this simple because of our `adSlotSelector` function
    // box-shadow: 0 0 0 1px red !important; // for debugging

    .l-cardgrid__item {
        height: 100%;
    }

    .c-card {
        margin-bottom: 0;

        .l-grid--cardheader .l-grid__cell--shrink { // tooltip
            display: none;
        }
    }
}
