.o-weathermap {
    position: relative;
    $width: 540;
    aspect-ratio: $width #{"/ 500"};

    &__base {
        width: 100%;
        height: auto;
    }
    &__cities, &__data {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
    }

    &__data {
        z-index: 1;
    }

    &__cities {
        z-index: 2;
        filter: drop-shadow(0px 0px 2px black); // "text-shadow" for city overlay for better readability over rain
    }

    &__flag {
        @extend .c-image__flag;
        z-index: 1;
    }

    .is-skeleton & {
        img {
            background: transparent !important;
        }

        img:before {
            display: none !important;
        }

        .o-weathermap__data {
            display: none;
        }
    }
}

.o-weathermap-legend {
    display: inline-block;
    width: rem(180);
    p {
        font-size: rem(14);
        margin-bottom: rem(5);
        font-weight: 700;
    }
    img {
        display: inline;
    }
}

/**
* Component used on the weather details page.
*/
.c-weather-search-callout {
    container-type: inline-size;
    container-name: weathersearchbox;

    &__content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: $global-margin;
    }

    &__col {
        position: relative;
        flex: 1 1 100%;
        text-align: center;
    }

    &__col--center {
        margin: auto;
        max-width: rem(500);
        text-align: center;
    }

    &__col--right { // contains extra icon decorations. shown via container query
        display: none;
    }

    &__form {
        margin: auto;
        max-width: rem(400);
    }

    // Icon settings

    &__icon-1 {
        margin-bottom: $global-margin;
        width: rem(130);
        height: rem(164);
        fill: cssvar(color-secondary);
    }

    &__icon-2 {
        position: absolute;
        top: rem(38);
        left: rem(38);
        width: rem(45);
        height: rem(65);
    }

    &__icon-3 {
        position: absolute;
        bottom: 0;
        right: rem(24);
        width: rem(72);
        height: rem(107);
        fill: cssvar(color-secondary);
    }

    &__icon-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

// Container queries
@container weathersearchbox (min-width: #{rem(574)}) {
    .c-weather-search-callout__content {
        flex-wrap: nowrap;
    }

    .c-weather-search-callout__col{
        flex-basis: rem(216);
    }
}

@container weathersearchbox (min-width: #{rem(900)}) {
    .c-weather-search-callout__col--right {
        display: block;
    }
}
/*
* Weather widget that is seen on the weather details page.
* Shows the 3 day forecast and exists within a card component.
*/
.c-weather-forecast {
    display: flex;
    gap: 5%;
    margin: calc(cssvar(grid-gutter));

    @include breakpoint(medium) {
        flex-direction: column;
    }

    @include breakpoint(large) {
        flex-direction: row;
    }

    // Sections - common class needed for breakpoints
    &__col {

        @include breakpoint(medium) {
            flex-basis: 100%;
            text-align: center;
        }

        @include breakpoint(large) {
            text-align: initial;
        }
    }

    &__header {
        flex: 1 1 50%;
        order: 1;

        @include breakpoint(large) {
            flex-basis: 40%;
            order: 2;
            align-self: center;

            svg {
                margin-bottom: $global-margin;
            }
        }
    }

    &__data {
        flex: 1 1 60%;
        margin: rem(15) 0;
        order: 2;

        @include breakpoint(large) {
            flex-basis: 60%;
            margin: 0;
            order: 1;
        }

    }

    &__details {
        list-style-type: none;
        padding-inline-start: 0;
        margin: 0;
        @include breakpoint(large) {
            margin-top: rem(80);
        }
    }

    // Content
    &__icon {
        display: block;
        margin: auto;
        stroke: currentColor;
        fill: currentColor;
        width: rem(70);
        height: rem(70);
    }

    &__airtemp-highlight {
        // Used on the currentweather box on the detail page only
        font-size: rem(48);
        font-family: cssvar(header-font-family);
        line-height: rem(40);
        margin: 0;

    }

    // Variations

    &--currentweather {
        // Component found on the weather details page, top part of the page.

        // Handles icon positioning
        .c-weather-forecast__header {
            display: flex;
            flex-direction: column;
        }

        @include breakpoint(medium) {
            height: 100%; // adding the height here and on the card itself, see below, allows justify-content to work
            justify-content: space-between;
        }

        @include breakpoint (large) {
            flex-direction: row;

            .c-weather-forecast__icon {
                width: rem(200);
                height: auto;
                margin-left: 40%;
            }
            .c-weather-forecast__col {
                flex-basis: 50%;
                text-align: left;
            }
        }

        // Special icon treatment
        @media screen and (min-width: rem(808)) and (max-width: map-get($breakpoints, 'large')) {
            .c-weather-forecast__icon {
                width: 80%;
                height: 80%;
                margin-top: 10%;
            }
        }
    }
}

/*
* Weather layout settings for the header on the details page.
*/

.l-weather-details-header {
    @include breakpoint (medium) {
        .c-card--currentweather {
            // control this card's size so that it matches with the radar map next to it
            // Numbers were measured in the browser - width of current weather col, height of the radar col as that image influences how it scales.
            aspect-ratio: 406 / 532;

            .c-card__content {
                height: 100%;
            }
        }
    }

    // The grid div containing the next hours module on the AT version of the website.
    // Needed for spacing issue.
    &__next-hours {
        margin-top: $global-margin;
        @include breakpoint(medium) {
            margin-top: 0;
            margin-bottom: 0;
        }

        li:last-child {
            .c-card {
                margin-bottom: 0;
            }
        }
    }

    .c-card--currentweather--at {
        // specific changes to layout based on austria website
        // where we do not have a radar card
        --cardminheight: 100%;

        @include breakpoint(medium) {
            font-size: rem(18);
        }
    }
}
