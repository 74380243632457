// main#main added here to increase specificy of the selectors
main#main .c-searchresults {

    // This is all hacky styles that try to override googles style
    // This may need constant adjustments when google is rolling out changes

    --color-primary: #{cssvar(color-primary)};

    @include tpl(rockantenne) {
        --color-primary: #{cssvar(color-white)};
    }

    .gsc-control-cse {
        padding: 0;
        background: transparent;
        border-color: transparent;
        font-family: cssvar(body-font-family);
    }

    > :not(.c-embed) * {
        background: transparent;
    }

    .gsc-search-box.gsc-search-box-tools,
    .gsc-tabsArea {
        display: none !important;
    }

    .gsc-above-wrapper-area,
    .gsc-above-wrapper-area-container,
    .gsc-above-wrapper-area-container td {
        box-shadow: none;
        border: none;
        border-radius: 0;
        margin: 0;
        padding: 0;
    }

    .gsc-result-info {
        padding: 0;
        font-size: rem(14);
        color: var(--color-primary);
    }

    .gsc-orderby-container {
        display: none;
    }

    .gsc-webResult.gsc-result {
        border: none;
        background: transparent;
    }

    .gs-webResult.gs-result {
        display: flex;
        flex-direction: column;
        border: none;
    }

    .gs-web-image-box .gs-image {
        max-width: 100%;
        border: none;
        vertical-align: top;

        @include tpl(antenne) {
            border-radius: $global-radius;
        }
    }

    .gsc-thumbnail-inside,
    .gsc-url-top,
    .gsc-table-result {
        padding-left: 0;
    }

    .gs-web-image-box,
    .gs-promotion-image-box {
        width: rem(150);
        padding-right: cssvar(grid-gutter);

        @include breakpoint(medium) {
            width: rem(220);
        }
    }

    .gs-title {
        @extend .o-headline--size5;
        margin-top: rem(2);
        margin-bottom: rem(3);
        padding-left: 0;

        * {
            font-size: inherit;
            color: inherit;
        }
    }

    .gsc-url-top {
        margin-top: rem(24);
        margin-bottom: rem(2);
        order: -1;
        font-family: cssvar(body-font-family);
    }

    .gs-webResult div.gs-visibleUrl {
        font-size: rem(14);
        color: var(--color-primary);
    }

    .gs-snippet {
        line-height: 1.75;
        font-size: 1rem;
        color: var(--color-primary);
        max-width: rem(580);
        font-family: cssvar(body-font-family);
        accent-color: cssvar(color-primary);
    }

    .gsc-results .gsc-cursor-box {
        text-align: center;
        margin: 2rem auto;
    }

    .gsc-results .gsc-cursor-box .gsc-cursor-page {
        color: var(--color-primary);
        font-size: rem(18);
        font-weight: bold;

        &.gsc-cursor-current-page {
            border-bottom: 4px solid cssvar(color-secondary);
        }
    }

    .gcsc-find-more-on-google {
        margin: 0;
        padding: 0;
        color: #9a9a9a;
    }

    .gcsc-more-maybe-branding-root {
        margin-top: 2rem;
        display: flex;
        flex-direction: column;
        text-align: center;

        >a {
            order: 2;
            display: inline-block;
            margin: 0 auto;
        }

        .gcsc-find-more-on-google-branding {
            order: 1;
            float: none;
            display: inline-block;
            margin: 0 auto;
        }
    }

    .gcsc-find-more-on-google-magnifier {
        fill: currentColor;
    }
}
