// Global prefix for CSS Custom Properties (automagically added)
$cssvar-prefix: 'antenne';

$global-weight-normal: 400;
$global-weight-bold: 700;

// Base Settings
$global-width: rem(1050);
$global-margin: rem(16);
$global-radius: #{cssvar(border-radius)};
$box-shadow: 0px 0px 10px 2px rgba(15, 23, 42, 0.06), 0px 4px 6px -1px rgba(15, 23, 42, 0.1);

// Global Responsive Breakpoints
$breakpoints: (
    small: 0,
    medium: 600px,
    large: 1020px,
);
$breakpointSidebar: rem(800);
$media-direction: 'min';

//sizes
$typogrid: 0.75rem;
$extra-class-sizes: (
    "small": $typogrid * 1,
    "medium": $typogrid * 2,
    "large": $typogrid * 3,
    "xlarge": $typogrid * 4,
    "xxlarge": $typogrid * 6,
    "grid": calc(cssvar(grid-gutter) / 2),
);

// Grid / Content-Container
$grid-column-count: 12;

$newstickerHeight: rem(44);
