$header-item-spacing: rem(10) !default;

.l-page-footer {
    @include setcssvar(color-foreground, cssvar(color-header-foreground, cssvar(color-white)));
    margin: 0; //important for RA background
    padding: 0 0 cssvar(safe-area-bottom);
    background-color: cssvar(color-primary);
    color: cssvar(color-foreground);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    a {
        font-size: rem(16);
        text-decoration: none;
        color: cssvar(color-foreground);
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        &:hover {
            color: cssvar(color-secondary)
        }
    }

    @include tpl(oldieantenne) {
        background: cssvar(color-background);
    }
}

.c-footer {
    &__logo-wrapper {
        width: fit-content;
        margin-left: auto;
    }

    &__logo {
        display: block;
        width: auto;
        height: rem(46);
        width: auto;
        --move: #{rem(-22)};
        transform: translateY(var(--move));
        margin-bottom: rem(-30);

        @include tpl(rockantenne) {
            --move: #{rem(-24)};
        }

        @include breakpoint(medium) {
            height: rem(66);
            --move: #{rem(-31)};

            @include tpl(rockantenne) {
                --move: #{rem(-28)};
            }

            html.context--web-rockantenne-de & {
                --move: #{rem(-35)}
            }
        }
    }

    &__content {
        padding: rem(47 0 0);

        @include breakpoint(medium, down) {
            padding: rem(74 0 0);
            position: relative;
            flex-direction: column;
            justify-content: flex-start;

            &:before {
                content: '';
                flex-basis: 100%;
                width: 0;
                order: 3;
            }
        }

        .l-grid__cell {

            &:first-child {
                @include breakpoint(medium, down) {
                    order: 1
                }
            }

            &:nth-child(2) {
                @include breakpoint(medium, down) {
                    order: 5
                }
            }

            &:nth-child(3) {
                @include breakpoint(medium, down) {
                    order: 2;
                }
            }

            &:nth-child(4) {
                @include breakpoint(medium, down) {
                    order: 6;
                }
            }

            &:nth-child(5) {
                @include breakpoint(medium, down) {
                    order: 4;
                }
            }
        }
    }

    &__heading {
        display: none;
        margin-bottom: rem(30);
        font-weight: bold;
        letter-spacing: cssvar(header-font-spacing);
        font-family: cssvar(header-font-family);

        @include breakpoint(large) {
            display: inline-block;
        }
    }

    &__list {
        padding: 0;
        margin: 0;

        li {
            list-style: none;
            margin-bottom: rem(20);

            @include breakpoint(medium) {
                &:not(:last-child) {
                    margin-bottom: rem(10);
                }
            }
        }
    }

    &__stationselect {
        svg {// needed for proper placement of arrow icon
            margin-bottom: rem(4);
        }
    }
}
