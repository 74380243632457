/* ==========================================================================
   social media links Component
   - Used in the footer, offcanvas navigation
   ========================================================================== */

.c-sociallinks {

    ul {
        padding: 0;
        margin: rem(0 0 0 -8);
        list-style-type: none;
        display: flex;
        flex-wrap: wrap;
        width: fit-content;
    }

    a {
        display: flex;
        padding: rem(12 8);
    }

    button {
        display: flex;
        padding: rem(12 8);
    }

    // Variations
    &--offcanvas {
        margin-block: rem(12);

        @include breakpoint(large) {
            margin-block: rem(32);
        }

        a {
            padding: rem(12);
        }
    }

    &--footer {

        //offset link padding
        margin-top: rem(-12);

        @include breakpoint(medium, down) {
            position: absolute;
            left: rem(5);
            top: rem(20);
        }

        @include breakpoint(medium) {
            margin-bottom: rem(22);
        }
    }
}
