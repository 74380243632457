table {
    margin-bottom: $global-margin;
    border-collapse: collapse;
    border-style: hidden;
    width: 100%;
    overflow: hidden;

    @include tpl(antenne) {
        border-radius: $global-radius;
        box-shadow: $box-shadow;
    }
}

td,
th {
    padding: rem(3 10);
    border: rem(1) solid cssvar(color-lightgray);
    text-align: center;
}
