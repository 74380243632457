.c-fullscreenplayer {
    position: fixed;
    background: cssvar(color-primary);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 110;

    &__overlay {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__fadeOut {
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s linear 200ms, opacity 200ms;
    }

    &__fadeIn {
        visibility: visible;
        opacity: 1;
        transition: visibility 0s linear 0s, opacity 200ms;
    }

    &__minimize-button {
        cursor: pointer;
    }

    &__icon {
        color: cssvar(color-white);
        height: rem(21);
        width: rem(21);
    }
}