/* ==========================================================================
   Utility classes
   - Flex order based on breakpoints
   - Visibility
   - Background
   - Decoration
   - Animations
   - Spacing
   - Typography
   - Display
   - Align
   - Position
   - Icons
   - Miscellaneous
   ========================================================================== */


// Element flex ordering based on media queries

// TODO this is not being used in MODX anywhere. Delete it? Would save us a lot of lines of code
// @each $breakPointName,
//$breakpointValue in $breakpoints {
//    @include breakpoint($breakPointName) {
//         @for $idx from 1 through $grid-column-count {
//              .#{"u-" + $breakPointName + "-" + "order-" + $idx} {
//                 order: #{$idx};
//             }
//          }
//     }
//  }

/*
 * Visibility State Classes
  - overflow
  - Screen reader only class
  - Hide classes
  - Show classes
  - Show Only classes
*/

.u-show-overflow {
    overflow: visible !important;
}

.u-sr-only {
    // ref. https: //css-tricks.com/inclusively-hidden/
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    clip-path: inset(50%); // future browsers - clip will be deprecated
    white-space: nowrap;
    border-width: 0;

    &-reset {
        position: relative;
        width: inherit;
        height: inherit;
        margin: initial;
        padding: initial;
        overflow: inherit;
        clip: auto;
        clip-path: initial;
        white-space: initial;
        border-width: initial;
    }
}


/*
*  Hide Classes
*  Example use cases:
*
* <div class="u-hide-medium">
*    <h1>Show small only</h1>
* </div>
* <div class="u-show-medium u-hide-large">
*    <h1>Show Medium only</h1>
* </div>
* <div class="u-show-medium">
*   <h1>Show medium up</h1>
* </div>
* <div class="u-show-large">
*    <h1>Show large up / same as show large only in this project </h1>
* </div>
*/

.u-hide {
    display: none !important;
}

// hide medium up
.u-hide-medium {
    @include breakpoint(medium) {
        display: none !important;
    }
}

// hide large up
.u-hide-large {
    @include breakpoint(large) {
        display: none !important;
    }
}

html.template--webview .u-hide-in-webview {
    display: none !important;
}

/*
*  Show Classes
*/

// medium up
.u-show-medium {
    // === u-hide-small-only
    @include show-for(medium);
}

// large up
.u-show-large {
    // === u-hide-small-medium-only
    @include show-for(large);
}

html:not(.template--webview) .u-show-in-webview {
    display: none !important;
}

/*
* Background Classes
*/

.u-background-darkblue {
    background-color: cssvar(color-darkblue) !important;
}

.u-background-green {
    background-color: cssvar(color-green) !important;
    --button-backgroundcolor: cssvar(color-green) !important;

    &:hover {
        color: cssvar(color-white);
    }
}

.u-background-red {
    background-color: cssvar(color-alert) !important;
    --button-backgroundcolor: cssvar(color-alert) !important;

    &:hover {
        color: cssvar(color-white);
    }
}

.u-background-white-large {
    @include breakpoint(large) {
        background: white;
        color: currentColor;
    }
}

.u-background-transparent {
    background-color: transparent;
}

/*
* Decoration Classes
*/

.u-separator {
    position: relative;
    margin-block: rem(10);
    width: rem(88);
    height: rem(6);

    @include tpl(antenne,oldieantenne) {
        margin: rem(12 0 10 0);
        background-color: cssvar(color-secondary);
    }

    @include tpl(rockantenne) {
        width: rem(64);
        height: rem(1); //neccessary so margin works
    }

    .c-heading & {
        top: rem(-5);
    }
}

/*
* Animation
*/

.u-fade-out {
    animation: fadeOut .5s ease-out both;
}

.u-shake-horizontal {
    animation: shake-horizontal 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
}

.u-pulsate {
    animation: pulsate 1s linear infinite;
}

.u-jello-horizontal {
	animation: jello-horizontal 0.9s;
}

.u-scrolltext {
    animation: scrollText linear infinite;
    animation-duration: var(--scroll-duration, 9s);
    white-space: nowrap;
}

/*
*  Spacing classes
*/
@media not print {

    .u-auto-margin-inline {
        margin-inline: auto;
    }

    .u-no-margin {
        margin: 0 !important;
    }

    .u-no-margin--top {
        margin-top: 0 !important;
    }

    .u-no-margin--bottom {
        margin-bottom: 0 !important;
    }

    .u-no-margin--left {
        margin-left: 0 !important;
    }

    .u-no-margin--right {
        margin-right: 0 !important;
    }

    .u-no-padding {
        padding: 0 !important;
    }

    .u-no-padding--top {
        padding-top: 0 !important;
    }

    .u-no-padding--bottom {
        padding-bottom: 0 !important;
    }

    @each $name,
    $size in $extra-class-sizes {
        .u-extra-#{$name}-margin {
            margin: $size !important;
        }

        .u-extra-#{$name}-margin--top {
            margin-top: $size !important;
        }

        .u-extra-#{$name}-margin--right {
            margin-right: $size !important;
        }

        .u-extra-#{$name}-margin--bottom {
            margin-bottom: $size !important;
        }

        .u-extra-#{$name}-margin--left {
            margin-left: $size !important;
        }

        .u-extra-#{$name}-padding {
            padding: $size !important;
        }

        .u-extra-#{$name}-padding--top {
            padding-top: $size !important;
        }

        .u-extra-#{$name}-padding--right {
            padding-right: $size !important;
        }

        .u-extra-#{$name}-padding--bottom {
            padding-bottom: $size !important;
        }

        .u-extra-#{$name}-padding--left {
            padding-left: $size !important;
        }
    }
}

/*
* Typography Helpers
*/

.u-line-clamp {
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-word;
    -webkit-box-orient: vertical;
    line-clamp: var(--maxlines-small, 1);
    -webkit-line-clamp: var(--maxlines-small, 1);

    @include breakpoint(medium) {
        line-clamp: var(--maxlines-medium, var(--maxlines-small, 1));
        -webkit-line-clamp: var(--maxlines-medium, var(--maxlines-small, 1));
    }
}

.u-text-center {
    text-align: center;
}

.u-text-left {
    text-align: left;
}

.u-text-right {
    text-align: right;
}

.u-text-uppercase {
    text-transform: uppercase;
}

.u-text-center-medium {
    @include breakpoint(medium) {
        text-align: center;
    }
}

.u-text-left-large {
    @include breakpoint(large) {
        text-align: left;
    }
}

.u-color {
    color: var(--color, cssvar(color-primary));
}

.u-text-green, .u-text-success {
    color: cssvar(color-success);

    &:hover {
        color: cssvar(color-success);
    }
}

.u-text-red, .u-text-alert {
    color: cssvar(color-alert);

    &:hover {
        color: cssvar(color-alert);
    }
}

/*
* Display helpers
*/

.u-inline-block {
    display: inline-block;
}

.u-flex-medium {
    @include breakpoint(medium) {
        display: flex;
    }
}

.u-flex-large {
    @include breakpoint(large) {
        display: flex;
    }
}

.u-flex {
    display: flex;
    &--column {
        flex-direction: column;
    }
}

.u-flex-align-center {
    justify-content: center;
}

.u-flex-vertical-centered {
    align-items: center;
}

.u-flex-centered {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.u-block {
    display: block !important; // important so it can override display none regardless of CSS cascade
}

/*
* Align
*/

.u-align-self-end {
    align-self: end;
}


/*
* Justify
*/

.u-justify-end {
    justify-content: end;
}

/*
* Position
*/

.u-position-relative {
    position: relative;
}

/*
* Icons
*/

.u-icon--square {
    max-width: var(--icon-size, rem(32));
    max-height: var(--icon-size, rem(32));
    width: 100%;
    height: 100%;
}

.u-icon--landscape {
    max-width: var(--icon-width, rem(68));
    max-height: var(--icon-height, rem(28));
    width: 100%;
    height: 100%;
}

.u-icon--stroke {
    stroke: currentColor;
    fill: transparent;
    transition: stroke .3s ease-in-out;
}

/*
* Misc
*/

//modal utility - used currently in the toolbar copy url button as user feedback
.u-modal {
    background-color: cssvar(color-white);
    color: cssvar(color-primary);
    position: absolute;
    z-index: 100;
    padding: rem(5 20);
    margin-top: rem(5);
    box-shadow: $box-shadow;
    border-radius: $global-radius;
    user-select: none;
    transform: translateX(-50%);

    p {
        margin: 0;
    }
}

a.u-faux-block-link-overlay {
    z-index: 10;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    opacity: 0;
    text-indent: 200%;
    white-space: nowrap;
    background: transparent;
    cursor: pointer;
}

.u-whitespace-ifnotempty:not(:empty)::before {
    content: " ";
}

.u-magical-audiocard-height {
    @include breakpoint(large) {
        height: rem(265);
        overflow: hidden;
    }
}

.u-disabled {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
}

.u-w-full {
    width: 100%;
}

.u-no-underline {
    text-decoration-line: none;
}

.u-link-no-deco {
    text-decoration: none;
}

.u-widget-content-loading {
    content: "";
    display: block;
    width: rem(22);
    height: rem(22);
    // top: calc(50% - #{rem(7)});
    // left: calc(50% - #{rem(7)});
    background: currentColor;
    border-radius: 50%;
    transform: scale(1);
    animation: heartbeat 2s infinite;
}

.u-fill--none {
    fill: none;
}

// Used in our bookmark widgets as a visual cue when the user removes a bookmark
.has-bookmark-removed {
    pointer-events: none;
    opacity: 0.4;
}
